import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, AfterViewInit, HostListener, ElementRef } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { ResourceDetails } from '@syncfusion/ej2-schedule';
import { ScheduleComponent, GroupModel } from '@syncfusion/ej2-angular-schedule';
import { debounceTime } from 'rxjs/operators';

import { DataService } from '../../shared/services/data.service';
import { SharedService } from '../../shared/services/shared.service';
import { Employee } from '../../entities/employees/models/employee.model';
import { Abscence } from '../../entities/abscences/models/abscence.model';
import { AuthService } from '../../shared/services/auth.service';
import { Subject, takeUntil } from 'rxjs';
import { hideSpinner, showSpinner } from '@syncfusion/ej2-angular-popups';
import { Router } from '@angular/router';

@Component({
	selector: 'app-yearly-overview',
	templateUrl: './yearly-overview.component.html',
	styleUrls: ['./yearly-overview.component.scss'], 
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class YearlyOverviewComponent implements OnInit, OnDestroy {
	@ViewChild('schedule') public schedule!: ScheduleComponent;
	@ViewChild('yearSelector') yearSelector: ElementRef;
	@ViewChild('ejDialog') ejDialog: any;

	public months: string[] = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
	public daysInMonths: number[] = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
	public daysPerMonth: Map<string, number[]> = new Map<string, number[]>();

	public group: GroupModel = { resources: ['Employees'] };
	public resources: ResourceDetails[] = [];

	public titleCasePipe = new TitleCasePipe();

	public employees: Employee[] = [];

	public cellClasses: Map<Employee, Map<number, Map<number, string>>> = new Map<Employee, Map<number, Map<number, string>>>();
	public tooltips: Map<Employee, Map<number, Map<number, string>>> = new Map<Employee, Map<number, Map<number, string>>>();

	isLeaveDescVisible:boolean = false;
	selectedLeave:any = {};

	isshowpopup:boolean=false;
	selectedDate:Date;
	syncFusionSelectedDate: any;
	isloading= false;

	public yearsList: number[] = [];
	private decadeStart: number = 0;

	private $destroy = new Subject<void>();
	private yearChange = new Subject<Date>();

	public month: number = new Date().getMonth();
  	public fullYear: number = new Date().getFullYear();
  	public dateValue: Date = new Date(this.fullYear, this.month, 11);
  	public minDate: Date = new Date(this.fullYear, this.month, 9);
  	public maxDate: Date = new Date(this.fullYear, this.month, 15);

	public constructor(
		public readonly dataService: DataService,
		public readonly sharedService: SharedService,
		private readonly changeDetector: ChangeDetectorRef,
		private readonly authService: AuthService,
		private readonly router:Router
	) {
	}

	public ngOnInit(): void {
		
		if (!(this.authService.isAdmin())) {
			this.router.navigate([''])
		}
		const appElement = document.getElementById('app') as HTMLElement;
		this.isloading = true;
		this.changeDetector.detectChanges();
			try {
				this.sharedService.changeComponentName('Jahresübersicht Abwesenheit');
				this.selectedDate = new Date(this.dateValue.getFullYear(), 0, 1);
				this.changeDetector.detectChanges();

				this.updateYearsList();
				this.employees = this.dataService.employeesCacheService.employees;
				this.showEntries();

				this.authService.selectedUser$.pipe(takeUntil(this.$destroy)).subscribe(() => {
						this.getEntries();
					});

				this.yearChange
					.pipe(takeUntil(this.$destroy), debounceTime(100))
					.subscribe((date) => {
						this.processYearChange(date);
					});
			} finally {
				this.isloading = false;
			}
	}
	
	private getEntries() {
		this.dataService.employeesService.employees$.pipe(takeUntil(this.$destroy)).subscribe(employees => {
			this.employees = employees;
			this.showEntries();
		});
	}

	private showEntries() {
		if (!this.authService.isAdmin()) {
			this.employees = this.employees.filter(employee => employee.outlet === this.authService.selectedEmployee.outlet);
		}

		this.employees.sort((a, b) => {
			const outletComparison = a.outlet.localeCompare(b.outlet);
			if (outletComparison !== 0) {
				return outletComparison;
			} else {
				const firstnameComparison = a.firstname.localeCompare(b.firstname);
				if (firstnameComparison !== 0) {
					return firstnameComparison;
				} else {
					return a.lastname.localeCompare(b.lastname);
				}
			}
		});

		this.update();
	}

	public update() {
		this.createDaysPerMonth();
		this.createCellClasses();
		this.createTooltips();

		this.changeDetector.detectChanges();

		console.log('Updated');
	}

	private createDaysPerMonth() {
		this.daysPerMonth.clear();
		for (let i = 0; i < this.months.length; i++) {
			this.daysPerMonth.set(this.months[i], Array.from({ length: this.daysInMonths[i] }, (_, i) => i + 1));
		}
	}

	private createCellClasses() {
		this.cellClasses.clear();

		for (const employee of this.employees) {
			const a = new Map<number, Map<number, string>>();

			for (const month of this.months) {
				const b = new Map<number, string>();

				for (const day of this.daysPerMonth.get(month)!) {
					b.set(day, this.getCellClass(employee, this.months.indexOf(month), day));
				}

				a.set(this.months.indexOf(month), b);
			}

			this.cellClasses.set(employee, a);
		}
	}

	private createTooltips() {
		this.tooltips.clear();

		for (const employee of this.employees) {
			const a = new Map<number, Map<number, string>>();

			for (const month of this.months) {
				const b = new Map<number, string>();

				for (const day of this.daysPerMonth.get(month)!) {
					b.set(day, this.getTooltip(employee, this.months.indexOf(month), day));
				}

				a.set(this.months.indexOf(month), b);
			}

			this.tooltips.set(employee, a);
		}
	}

	public getCellClassFromField(employee: Employee, monthIndex: number, day: number): string {
		return this.cellClasses.get(employee)?.get(monthIndex)?.get(day) || '';
	}
	
	public getTooltipFromField(employee: Employee, monthIndex: number, day: number): string {
		let tooltipText = this.tooltips.get(employee)?.get(monthIndex)?.get(day) || ''
		if (tooltipText.startsWith('Sonderurlaub')) {
			tooltipText = 'Gleitzeit' + tooltipText.slice('Sonderurlaub'.length);
		}
		return tooltipText;
	}
	

	private getCellClass(employee: Employee, month: number, day: number): string {
		if (employee.abscences) {
			for (const absence of employee.abscences) {
				const absenceCells = this.getAbsenceCells(absence);
				if (absenceCells.some(cell => cell.date.getFullYear() === this.dateValue.getFullYear() && cell.month === month && cell.day === day)) {
					return this.getAbsenceColor(absence.type);
				}
			}
		}
		return '';
	}

	private getTooltip(employee: Employee, month: number, day: number): string {
		if (employee.abscences) {
			for (const absence of employee.abscences) {
				const absenceCells = this.getAbsenceCells(absence);
				const matchingCell = absenceCells.find(cell => cell.month === month && cell.day === day);
				if (matchingCell) {
					return `${this.titleCasePipe.transform(matchingCell.type)}: ${matchingCell.text}`;
				}
			}
		}
		return 'Leer';
	}

	private getAbsenceCells(absence: Abscence): { month: number, day: number, type: string, date: Date, text: string }[] {
		const startDate = new Date(absence.from);
		//console.log('startDate: ', startDate)
		const endDate = new Date(absence.to);

		const formattedStartDate = new Intl.DateTimeFormat('de-DE', {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
		}).format(startDate);

		const formattedEndDate = new Intl.DateTimeFormat('de-DE', {
			day: '2-digit',
			month: '2-digit',
			year: 'numeric',
		}).format(endDate);

		const cells: any = [];

		for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
			if(cells.length>0&& cells[length-1]?.month>d?.getMonth()){
				continue;
			}
			cells.push({month: d.getMonth(), day: d.getDate(), type: absence.type, date: new Date(d), text:`von ${formattedStartDate} bis ${formattedEndDate}`});
		}

		return cells;
	}

	private getAbsenceColor(type: string): string {
		switch (type) {
			case 'Bezahlter Urlaub':
				return 'green';
			case 'Krankheit':
				return 'blue';
			case 'Feiertag':
				return 'grey';
			case 'Elternzeit':
				return 'yellow';
			default:
				return 'red';
		}
	}

	public trackByEmployee(index: number, employee: Employee): string {
		return employee.id;
	}
	
	public trackByMonth(index: number, month: string): string {
		return month;
	}
	
	public trackByDay(index: number, day: number): number {
		return day;
	}

	public onClickCell(employee: Employee, monthIndex: number, day: number){
		const cellClass = this.getCellClassFromField(employee, monthIndex, day);
		if(!!cellClass){
			this.isLeaveDescVisible = true;
			const leave = this.findLeaveByDate(employee.abscences||[],day,monthIndex)||{};
			this.selectedLeave = leave;
		}

	}

	public onDialogClose(): void {
		console.log('Dialog closed');
		this.isLeaveDescVisible = false;
		this.selectedLeave = {};
		this.changeDetector.detectChanges();
	}

	private findLeaveByDate(leaveData:Abscence[], day:number, month:number) {
		return leaveData.find((item) => {
			const fromDate = new Date(item.from);
			const toDate = new Date(item.to);

			const testDate = new Date(new Date().getFullYear(), month, day);

			return testDate >= fromDate && testDate <= toDate;
		});
	}

	public getSubstitutionName(employeeId): string {
		const matchedUser = this.dataService.employeesCacheService.employees.find((user: Employee) => user.id === employeeId);
		return !!matchedUser?`${matchedUser.firstname} ${matchedUser.lastname}`:'';
	}

	onYearSelected(event: any) {
		if (!event?.value) return;
		this.syncFusionSelectedDate = event.value;
		const date = new Date(event.value);
		const year = date.getFullYear();

		this.isshowpopup = false;
		document.documentElement.style.overflow = 'hidden';
		this.isloading = true;

		this.dateValue = new Date(year, this.month, 11);
		this.changeDetector.detectChanges();

		this.yearChange.next(this.selectedDate);
		this.changeDetector.detectChanges();
	}

	private async processYearChange(date: Date) {
		try {
			console.log(date);

			const year = date.getFullYear();
			this.selectedDate = new Date(year, 0, 1, 0, 0, 0);
			this.changeDetector.detectChanges();

			await Promise.resolve().then(() => {
				this.employees = this.dataService.employeesCacheService.employees.filter(employee => {
					if (!employee.startDate) return false;
					const startYear = new Date(employee.startDate).getFullYear();
					return startYear <= this.dateValue.getFullYear();
				});

				if (!this.authService.isAdmin()) {
					this.employees = this.employees.filter(employee =>
						employee.outlet === this.authService.selectedEmployee.outlet
					);
				}

				this.employees.sort((a, b) => {
					const outletComparison = a.outlet.localeCompare(b.outlet);
					if (outletComparison !== 0) {
						return outletComparison;
					}
					const firstnameComparison = a.firstname.localeCompare(b.firstname);
					if (firstnameComparison !== 0) {
						return firstnameComparison;
					}
					return a.lastname.localeCompare(b.lastname);
				});

				this.update();
			});
		} finally {
			document.documentElement.style.overflow = '';
			this.isloading = false;
			this.changeDetector.detectChanges();
		}
	}

	openYearSelector(event: Event) {
		event.stopPropagation();
		this.updateYearsList();
		this.isshowpopup = true;
		this.changeDetector.detectChanges();
	}

	@HostListener('document:click', ['$event'])
	clickout(event: any) {
		if (this.isshowpopup) {
			const calendarElement = document.querySelector('.popup-container');
			const buttonElement = document.querySelector('.calender-btn-wrapper');

			if (calendarElement && buttonElement) {
				if (!calendarElement.contains(event.target) && !buttonElement.contains(event.target)) {
					this.isshowpopup = false;
					this.changeDetector.markForCheck();
				}
			}
		}
	}

	@HostListener('document:click', ['$event.target'])
	public handleOutsideClick(target: HTMLElement) {
	  if (
		this.isLeaveDescVisible && 
		this.isLeaveDescVisible && this.selectedLeave.type && 
		!target.closest('.e-dlg') && // ensures clicks on dialog header etc. don't close
		!target.closest('.custom-tooltip') // in case clicks on tooltip or related elements shouldn't close
	  ) {
		this.onDialogClose();
	  }
	}
  

	ngOnDestroy(): void {
		this.$destroy.next();
		this.$destroy.complete();
	}

	private updateYearsList() {
		this.decadeStart = Math.floor(this.dateValue.getFullYear() / 10) * 10;
		this.yearsList = Array.from({length: 12}, (_, i) => this.decadeStart + i - 1);
	}

	public getDecadeRange(): string {
		return `${this.decadeStart} - ${this.decadeStart + 9}`;
	}

	public changeDecade(change: number) {
		this.decadeStart += change;
		this.updateYearsList();
		this.changeDetector.detectChanges();
	}

	public selectYear(year: number) {

		this.dateValue = new Date(year, this.month, 11);;
		this.changeDetector.detectChanges();
		this.isshowpopup = false;

		document.documentElement.style.overflow = 'hidden';
		this.isloading = true;

		this.yearChange.next(this.selectedDate);
		this.changeDetector.detectChanges();
	}
}
