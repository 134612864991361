<div class="Abwesenheitsuebersicht">
  <div class="upperRow">
    <div class="richText">
      <div><strong>Kalender</strong></div>
    </div>

    <!-- Submit -->
    <button ejs-button [cssClass]="'e-'+''" [isPrimary]="+'1' == 1" (click)="onButtonClicked()">
      Abwesenheit beantragen
    </button>
  </div>

  <div class="secondRow">

    <!-- YEAR SCHEDULER -->
    <div class="year-calender">
      <ejs-schedule [locale]="'de'" (navigating)="onNavigated($event)" (popupOpen)="onPopupOpen($event)"
        (actionComplete)="onActionComplete($event)" #scheduleObj [eventSettings]="eventSettings"
        [firstMonthOfYear]='0' [firstDayOfWeek]="1" [monthsCount]='12' (cellClick)="colorEvent($event)"
        class="calender-wrapper">
        <e-views>
          <e-view option='Year' showWeekNumber="true"></e-view>
        </e-views>
      </ejs-schedule>
    </div>

    <div class="timeEntry2">
      <div class="Layout">
        <div class="richText2">
          <div class="" contenteditable="false" data-block="true" data-editor="5tvlf" data-offset-key="5mv42-0-0">
            <div dir="auto">
              <div class="public-DraftStyleDefault-block public-DraftStyleDefault-ltr" data-offset-key="5mv42-0-0">
                <strong><span data-offset-key="5mv42-0-0"><span data-text="true">&Uuml;bersicht Jahresurlaub f&uuml;r {{currentYear}}</span></span></strong>
              </div>
            </div>
          </div>
        </div>
        <div class="Layout23">
          <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" width="15" xmlns="http://www.w3.org/2000/svg"
            style="-webkit-print-color-adjust: exact" id="screenshot-ba340de7-9585-802a-8003-8ca2c6559433" version="1.1"
            viewBox="1768.815 -2882.483 15 15" height="15">
            <g id="shape-ba340de7-9585-802a-8003-8ca2c6559433">
              <g class="fills" id="fills-ba340de7-9585-802a-8003-8ca2c6559433">
                <rect rx="7.5" ry="7.5" style="fill: #40c057; fill-opacity: 1" x="1768.814552265625"
                  y="-2882.4829714062507" transform="matrix(1.000000, 0.000000, 0.000000, 1.000000, 0.000000, 0.000000)"
                  width="15" height="15"></rect>
              </g>
            </g>
          </svg>
          <div class="hello">Genehmigter Urlaubstag</div>
        </div>

        <!-- <div class="Layout23">
          <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" width="15" xmlns="http://www.w3.org/2000/svg"
            style="-webkit-print-color-adjust: exact" version="1.1"
            viewBox="1768.815 -2882.483 15 15" height="15">
            <g>
              <g class="fills">
                <rect rx="7.5" ry="7.5" style="fill: #ff5602; fill-opacity: 1" x="1768.814552265625"
                  y="-2882.4829714062507" transform="matrix(1.000000, 0.000000, 0.000000, 1.000000, 0.000000, 0.000000)"
                  width="15" height="15"></rect>
              </g>
            </g>
          </svg>
          <div class="hello">Ausstehender Urlaubsantrag</div>
        </div> -->
        <div class="daysRow">
          <div class="richText234">
            <div>Anspruch</div>
          </div>
          <div class="richText234">
            <div>{{ contingentService.leaveEntitlement }} Tage</div>
          </div>
        </div>
        <div class="daysRow">
          <div class="richText234">
            <div>&Uuml;bertrag ({{ currentYear - 1 }})</div>
          </div>
          <div class="richText234">
            <div>{{ contingentService.carryOver }} Tage</div>
          </div>
        </div>
        <div class="daysRow">
          <div class="richText234">
            <div>Genommen</div>
          </div>
          <div class="richText234">
            <div>{{ contingentService.taken }} Tage</div>
          </div>
        </div>
        <div class="daysRow">
          <div class="richText234">
            <div>Geplant</div>
          </div>
          <div class="richText234">
            <div>{{ contingentService.planed }} Tage</div>
          </div>
        </div>
        <div class="Layout2345">
          <hr style="width: 100%; border: 1px solid #ccc" />
        </div>
        <div class="daysRow">
          <div class="richText234">
            <div>Verbleibende Tage</div>
          </div>
          <div class="richText234">
            <div>{{ contingentService.left }} Tage</div>
          </div>
        </div>
      </div>
      <div class="Layout2">
        <div class="richText22">
          <div class="" contenteditable="false" data-block="true" data-editor="5tvlf" data-offset-key="5mv42-0-0">
            <div dir="auto">
              <div class="public-DraftStyleDefault-block public-DraftStyleDefault-ltr" data-offset-key="5mv42-0-0">
                <div class="" contenteditable="false" data-block="true" data-editor="7fst3" data-offset-key="5mv42-0-0">
                  <div dir="auto">
                    <div class="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"
                      data-offset-key="5mv42-0-0">
                      <strong><span data-offset-key="5mv42-0-0"><span data-text="true">&Uuml;bersicht f&uuml;r
                            {{currentYear}}</span></span></strong>
                    </div>
                  </div>
                </div>
                <div class="" contenteditable="false" data-block="true" data-editor="7fst3" data-offset-key="dh1g3-0-0">
                  <div dir="auto">
                    <div class="public-DraftStyleDefault-block public-DraftStyleDefault-ltr"
                      data-offset-key="dh1g3-0-0">
                      <strong><span data-offset-key="dh1g3-0-0"><span data-text="true">ohne abzug vom Jahresurlaub</span></span></strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="daysRow">
          <div class="Layout23456">
            <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" width="15" xmlns="http://www.w3.org/2000/svg"
              style="-webkit-print-color-adjust: exact" id="screenshot-ba340de7-9585-802a-8003-8ca2c6559436"
              version="1.1" viewBox="1772.815 -2598.483 15 15" height="15">
              <g id="shape-ba340de7-9585-802a-8003-8ca2c6559436">
                <g class="fills" id="fills-ba340de7-9585-802a-8003-8ca2c6559436">
                  <rect rx="7.5" ry="7.5" style="fill: #000000; fill-opacity: 0.5" x="1772.814552265625"
                    y="-2598.4829714062507"
                    transform="matrix(1.000000, 0.000000, 0.000000, 1.000000, 0.000000, 0.000000)" width="15"
                    height="15"></rect>
                </g>
              </g>
            </svg>
            <div class="richText234">Feiertag</div>
          </div>
          <div class="richText234">
            <div>
              {{publicHolidaysCount || 0}} Tage</div>
          </div>
        </div>
        <div class="daysRow">
          <div class="Layout234562">
            <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" width="15" xmlns="http://www.w3.org/2000/svg"
              style="-webkit-print-color-adjust: exact" id="screenshot-ba340de7-9585-802a-8003-8ca2c6559438"
              version="1.1" viewBox="1772.815 -2569.483 15 15" height="15">
              <g id="shape-ba340de7-9585-802a-8003-8ca2c6559438">
                <g class="fills" id="fills-ba340de7-9585-802a-8003-8ca2c6559438">
                  <rect rx="7.5" ry="7.5" style="fill: #5c7cfa; fill-opacity: 1" x="1772.814552265625"
                    y="-2569.4829714062507"
                    transform="matrix(1.000000, 0.000000, 0.000000, 1.000000, 0.000000, 0.000000)" width="15"
                    height="15"></rect>
                </g>
              </g>
            </svg>
            <div class="richText234">Krankheitstage</div>
          </div>
          <div class="richText234">
            <div>{{ contingentService.abscencesByType['Krankheit'] || 0 }} Tage</div>
          </div>
        </div>
        <div class="daysRow">
          <div class="Layout2345622">
            <svg xmlns:xlink="http://www.w3.org/1999/xlink" fill="none" width="15" xmlns="http://www.w3.org/2000/svg"
              style="-webkit-print-color-adjust: exact" id="screenshot-ba340de7-9585-802a-8003-8ca2c655941a"
              version="1.1" viewBox="1772.815 -2541.483 15 15" height="15">
              <g id="shape-ba340de7-9585-802a-8003-8ca2c655941a">
                <g class="fills" id="fills-ba340de7-9585-802a-8003-8ca2c655941a">
                  <rect rx="7.5" ry="7.5" style="fill: #fcc419; fill-opacity: 1" x="1772.814552265625"
                    y="-2541.4829714062507"
                    transform="matrix(1.000000, 0.000000, 0.000000, 1.000000, 0.000000, 0.000000)" width="15"
                    height="15"></rect>
                </g>
              </g>
            </svg>
            <div class="richText234">Elternzeit</div>
          </div>
          <div class="richText234">
            <div>{{ contingentService.abscencesByType['Elternzeit'] || 0 }} Tage</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<leaveRequestDialog *ngIf="showAbwesenheitDialog" [publicHolidays]="publicHolidays" [abscence]="selectedAbscence" (next)="handleNext($event)"
  (closed)="closePopup()">
</leaveRequestDialog>
<confirmationDialog *ngIf="showConfirmationDialog" (closed)="closePopup()" (send)="handleSend()">
</confirmationDialog>