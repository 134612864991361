import {Component, DestroyRef, HostListener, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Employee} from 'src/app/entities/employees/models/employee.model';
import {DataService} from 'src/app/shared/services/data.service';
import {Dialog, DialogModel} from '@syncfusion/ej2-popups';
import {AbstractControl, FormArray, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {TargetVacationDays} from 'src/app/entities/target-vacation-dayses/models/target-vacation-days.model';
import {TargetWorkingHours} from 'src/app/entities/target-working-hourses/models/target-working-hours.model';
import {concatMap, from, Subject, takeUntil, tap} from 'rxjs';
import {InteractionsService} from "../../shared/services/interactions.service";
import * as XLSX from "xlsx";
import {HolidaysHttpRequestService} from "../../entities/holidays/services/holidays-http-request.service";
import {SharedService} from "../../shared/services/shared.service";
import moment from "moment";
import {GridComponent, GridModel} from "@syncfusion/ej2-angular-grids";
import {Abscence} from "../../entities/abscences/models/abscence.model";
import { v4 as uuid } from 'uuid'
import {isMobile} from "@syncfusion/ej2-angular-schedule";
import {ToastModel} from "@syncfusion/ej2-angular-notifications";
import {ToastUtility} from "@syncfusion/ej2-notifications";
import { AuthService } from 'src/app/shared/services/auth.service';
interface VacationEntitlement {
	id: string;
	amount: number;
	fromDate: Date;
	toDate: Date;
}

interface WorkingHours {
	id: string;
	fromDate: Date;
	toDate: Date;
	monday: number;
	tuesday: number;
	wednesday: number;
	thursday: number;
	friday: number;
	saturday: number;
	sunday: number;
}

enum roleTypes {
	Mitarbeiter = 'employee',
	Verwaltung = 'admin',
	Admin = 'Admin',
	Employee = 'Mitarbeiter',
	admin = 'Admin',
	employee = 'Mitarbeiter',
	user = 'employee',
	User = 'employee',
	'Niederlassungsadmin' = 'branch-admin',
	'branch-admin' = 'Niederlassungsadmin'
}

@Component({
	selector: 'app-employees-overview-page',
	templateUrl: './employees-overview-page.component.html',
	styleUrls: ['./employees-overview-page.component.scss'],
})
export class EmployeesOverviewPageComponent implements OnInit, OnDestroy {
	@ViewChild('dialog') public dialog: Dialog;
	@ViewChild('confirmDialog') public confirmDialog: any;
	@ViewChild('mainTable') public mainTable: GridComponent;
	public dataSource: Array<Employee> = [];
	public archivedEmployees: Array<Employee> = [];
	public employeeForm: FormGroup;
	public dialogVisible: boolean = false;
	public isEdit: boolean = false;
	public position = { X: 650, Y: 240 };
	public currentSectionIndex = 0;
	sectionName = 'Mitarbeiter Personaldaten';
	lastYearAbscences: any;
	plannedAbscences: any;
	targetVacationDays: any;
	currentEmployee: any;
	private fileData: any;
	germanStates: any;
	public currentYear: number = new Date().getFullYear();

	// Dialog configuration
	public dialogSettings: DialogModel = {
		isModal: true,
		showCloseIcon: true,
		closeOnEscape: true,
		enableResize: false,
		allowDragging: true,
		animationSettings: { effect: 'Zoom' }
	};

	outlets: string[] = [
		'Kassel',
		'Eschwege',
		'Eisenach',
		'Bad Arolsen',
		'Osnabrück',
		'Delmenhorst',
		'Friedrichshafen',
		'Unna',
		'Rheine',
		'Cloppenburg',
	];

	roles: any = [
		{ label: 'Mitarbeiter', value: 'employee' },
		{ label: 'Niederlassungsadmin', value: 'branch-admin' },
		{ label: 'Admin', value: 'admin' }
	];

	get vacationEntitlement() {
		return this.employeeForm?.controls['vacationEntitlement'] as FormArray;
	}

	get workingHours() {
		return this.employeeForm?.controls['workingHours'] as FormArray;
	}

	get employeeForm_() {
		return this.employeeForm?.controls['employee'];
	}

	private $destroy = new Subject<void>();
	private employeeToDelete: Employee | null = null;

	public constructor(
		private readonly dataService: DataService,
		private readonly destroyRef: DestroyRef,
		private readonly fb: FormBuilder,
		private readonly interactionsService: InteractionsService,
		private readonly holidaysHttpService: HolidaysHttpRequestService,
		private readonly sharedService: SharedService,
		private readonly authService: AuthService
	) {}

	public ngOnInit(): void {
		this.sharedService.changeComponentName('Mitarbeiterübersicht');
		this.initEmployees();
		this.initForm();
		this.holidaysHttpService.findGermanStates().subscribe((states: []) => {
			this.germanStates = states.map((state: any) => {
				return state.name;
			});
			console.log('STATES', this.germanStates);
		});
		this.dataService.abscencesService.findAll();

		// Initialize dialog settings if needed
		if (this.dialog) {
			this.dialog.close = () => {
				this.onCloseDialog();
			};
		}
	}

	public filterEmployees() {
		var employees = this.dataService.employeesCacheService.employees;

		if (this.authService.isBranchAdmin()) {
			const savedUserId = this.authService.currentEmployee.id;
			const matchedUser = this.dataService.employeesCacheService.employees.find(
				(user: Employee) => user.id === savedUserId
			);
			employees = employees.filter((e) => e?.outlet === matchedUser?.outlet);

			if (matchedUser && this.outlets.includes(matchedUser.outlet)) {
				this.outlets = [matchedUser.outlet];
			}
		}

		if (employees.length > 0) {
			employees.forEach((employee) => {
				if (
					employee.targetVacationDayses &&
					employee.targetVacationDayses.length > 0
				) {
					employee.remainingVacationDays =
						this.calculateRemainingVacationDays(employee);
				}
			});

			this.dataSource = employees.filter((employee) => !employee.isArchived);
			this.archivedEmployees = employees.filter(
				(employee) => employee.isArchived
			);

			if (this.mainTable) {
				this.mainTable.refresh();
			}
		}

		return employees;
	}

	public refreshTable(employees) {
		this.dataSource = employees.filter((employee) => !employee.isArchived);
		this.archivedEmployees = employees.filter(
			(employee) => employee.isArchived
		);
		if (this.mainTable) {
			this.mainTable.refresh();
		}
	}

	public initEmployees() {
		this.dataService.employeesService.employees$
			.pipe(takeUntil(this.$destroy))
			.subscribe(() => {
				this.filterEmployees();
			});
	}

	calculateRemainingVacationDays(employee: Employee): string {
		let remaining = 0;
		let totalTakenAbsenceDays = this.calculateTotalTakenAbsenceDays(employee);
		this.plannedAbscences = this.calculateTotalPlannedAbsenceDays(employee);
		this.lastYearAbscences = this.calculateLastYearAbscences(employee);
		remaining = employee.targetVacationDayses
			? employee.targetVacationDayses[0].amount -
			  totalTakenAbsenceDays +
			  this.lastYearAbscences -
			  this.plannedAbscences
			: 0;
		return this.convertDecimalToDaysAndHours(remaining);
	}

	calculateLastYearAbscences(employee: Employee): number {
		if (employee.abscences) {
			let filteredAbsences = employee.abscences.filter((ab: Abscence) => {
				return ab.type !== 'Feiertag';
			});

			if (filteredAbsences) {
				const lastYear = this.currentYear - 1;
				const lastYearAbsences = filteredAbsences.filter(
					(a: any) => a.from.getFullYear() === lastYear
				);
				this.lastYearAbscences = lastYearAbsences.length;
			}
		}
		return this.lastYearAbscences;
	}
	calculateTotalPlannedAbsenceDays(employee): number {
		if (employee.abscences) {
			let totalDays = 0;
			let currentDate = new Date();
			let filteredAbsences = employee.abscences.filter((ab: Abscence) => {
				return ab.type == 'Bezahlter Urlaub' && ab.from > currentDate;
			});

			for (let absence of filteredAbsences) {
				// Create date objects from the absence 'from' and 'to' dates
				let fromDate = new Date(absence.from);
				let toDate = new Date(absence.to);

				// Calculate the difference in time between the two dates
				let diffInTime = toDate.getTime() - fromDate.getTime();

				// Calculate the difference in days and add 1 to include the start day
				let diffInDays = diffInTime / (1000 * 3600 * 24) + 1;

				// Add the difference in days to the total
				totalDays += diffInDays;
			}
			return totalDays;
		} else {
			return 0;
		}
	}

	calculateTotalTakenAbsenceDays(employee): number {
		if (employee.abscences) {
			let totalDays = 0;
			let currentDate = new Date();
			let filteredAbsences = employee.abscences.filter((ab: Abscence) => {
				return ab.type == 'Bezahlter Urlaub' && ab.from <= currentDate;
			});

			for (let absence of filteredAbsences) {
				// Create date objects from the absence 'from' and 'to' dates
				let fromDate = new Date(absence.from);
				let toDate = new Date(absence.to);

				// Calculate the difference in time between the two dates
				let diffInTime = toDate.getTime() - fromDate.getTime();

				// Calculate the difference in days and add 1 to include the start day
				let diffInDays = diffInTime / (1000 * 3600 * 24) + 1;

				// Add the difference in days to the total
				totalDays += diffInDays;
			}
			return totalDays;
		} else {
			return 0;
		}
	}

	convertDecimalToDaysAndHours(decimal: number): string {
		const days = Math.floor(decimal);
		const hours = Math.round((decimal - days) * 8);

		return `${days} Tage und ${hours} Stunden`;
	}

	calculateTotalAbsenceDays(): number {
		let totalDays = 0;

		for (let absence of this.currentEmployee.abscences) {
			// Create date objects from the absence 'from' and 'to' dates
			let fromDate = new Date(absence.from);
			let toDate = new Date(absence.to);

			// Calculate the difference in time between the two dates
			let diffInTime = toDate.getTime() - fromDate.getTime();

			// Calculate the difference in days and add 1 to include the start day
			let diffInDays = diffInTime / (1000 * 3600 * 24) + 1;

			// Add the difference in days to the total
			totalDays += diffInDays;
		}
		return totalDays;
	}

	initForm(): void {
		this.employeeForm = this.fb.group({
			employee: this.fb.group({
				id: [''],
				firstname: ['', Validators.required],
				lastname: ['', Validators.required],
				role: ['', Validators.required],
				outlet: ['', Validators.required],
				// overtime: [''],
				email: ['', [Validators.required, Validators.email]],
				birthday: ['', Validators.required],
				startDate: ['', Validators.required],
				stateToApply: ['', Validators.required],
			}),
			vacationEntitlement: this.fb.array([]),
			workingHours: this.fb.array([]),
		});
	}

	public onAddClick(event:MouseEvent): void {
		event.stopPropagation();
		this.isEdit = false;
		this.employeeForm.reset();
		this.dialogVisible = true;
		if (this.dialog) {
			this.dialog.show();
		}
	}

	public onEditClick(employee: Employee): void {
		this.isEdit = true;
		const vacationEntitlement: VacationEntitlement[] =
			employee.targetVacationDayses
				? employee.targetVacationDayses
						.sort((a, b) => a.from.getTime() - b.from.getTime())
						.map((vacationday) => {
							return {
								amount: vacationday.amount,
								fromDate: vacationday.from,
								toDate: vacationday.to,
								id: vacationday.id,
							};
						})
				: [];

		vacationEntitlement.forEach((ve) => {
			this.pushVacationEntitlementForm(true);
		});

		const workingHours: WorkingHours[] = employee.targetWorkingHourses
			? employee.targetWorkingHourses
					.sort((a, b) => a.from.getTime() - b.from.getTime())
					.map((working) => {
						return {
							id: working.id,
							fromDate: working.from,
							toDate: working.to,
							monday: working.monday,
							tuesday: working.tuesday,
							wednesday: working.wednesday,
							thursday: working.thursday,
							friday: working.friday,
							saturday: working.saturday,
							sunday: working.sunday,
						};
					})
			: [];

		workingHours.forEach((wh) => this.pushWorkingHoursForm(true));

		this.employeeForm.patchValue({
			employee: {
				id: employee.id,
				firstname: employee.firstname,
				lastname: employee.lastname,
				role: employee.role,
				// overtime: employee.overtime,
				email: employee.email,
				isArchived: employee.isArchived,
				birthday: employee.birthday,
				startDate: employee.startDate,
				outlet: employee.outlet,
				stateToApply: employee.stateToApply,
			},
			vacationEntitlement: vacationEntitlement,
			workingHours: workingHours,
		});
		this.dialogVisible = true;

		console.log(this.employeeForm);
	}

	public onSave(): void {
		const vacationEntitlement = this.employeeForm.controls[
			'vacationEntitlement'
		].value as VacationEntitlement[];
		const workingHours = this.employeeForm.controls['workingHours']
			.value as WorkingHours[];
		const employeeID = this.employeeForm.controls['employee'].value.id;
		const abscences = employeeID
			? (this.dataService.abscencesCacheService.abscences.filter(
					(abscence) => abscence.employee && abscence.employee.id === employeeID
			  ) as Abscence[])
			: [];

		const targetVacationDays: TargetVacationDays[] = vacationEntitlement.map(
			(entitlement) => {
				return {
					id: entitlement.id,
					amount: Number(entitlement.amount),
					from: entitlement.fromDate,
					to: entitlement.toDate,
					employee: this.employeeForm.value.employee,
				};
			}
		);

		const targetWorkingHours: TargetWorkingHours[] = workingHours.map(
			(workingHours) => {
				return {
					id: workingHours.id,
					from: workingHours.fromDate,
					to: workingHours.toDate,
					monday: Number(workingHours.monday),
					tuesday: Number(workingHours.tuesday),
					wednesday: Number(workingHours.wednesday),
					thursday: Number(workingHours.thursday),
					friday: Number(workingHours.friday),
					saturday: Number(workingHours.saturday),
					sunday: Number(workingHours.sunday),
					employee: this.employeeForm.value.employee,
				};
			}
		);

		if (this.isEdit) {
			if (targetWorkingHours.length === 0 || targetVacationDays.length === 0) {
				let toast: ToastModel = ToastUtility.show(
					'Bitte füllen Sie die fehlenden Angaben aus: Soll-Arbeitsstunden und Soll-Urlaubstage.',
					'Error',
					10000
				);
				return;
			}
			this.dataService.employeesService.update(
				this.employeeForm.value.employee
			);

			targetVacationDays.forEach((targetVacation) => {
				if (targetVacation.id) {
					this.dataService.targetVacationDaysesService.update(targetVacation);
				} else {
					this.dataService.targetVacationDaysesService.create(targetVacation);
				}
			});

			targetWorkingHours.forEach((targetWorking) => {
				if (targetWorking.id) {
					this.dataService.targetWorkingHoursesService.update(targetWorking);
				} else {
					this.dataService.targetWorkingHoursesService.create(targetWorking);
				}
			});

			abscences.forEach((abscence) => {
				if (abscence.id) {
					this.dataService.abscencesService.update(abscence);
				} else {
					this.dataService.abscencesService.update(abscence);
				}
			});

			this.dataService.reloadEmployees();
			this.dataService.reloadAbsences();
			this.dataService.reloadTargetVacations();
			this.dataService.reloadWorkingHours();
		} else {
			const { id, ...createData } = this.employeeForm.value.employee;
			if (targetWorkingHours.length === 0 || targetVacationDays.length === 0) {
				let toast: ToastModel = ToastUtility.show(
					'Bitte füllen Sie die fehlenden Angaben aus: Soll-Arbeitsstunden und Soll-Urlaubstage.',
					'Error',
					10000
				);
				return;
			}
			// createData.role = roleTypes[createData.role];
			createData.isArchived = false;
			createData.birthday = moment(createData.birthday)
				.utc(true)
				.locale('de')
				.startOf('day')
				.toDate();
			createData.startDate = moment(createData.startDate)
				.utc(true)
				.locale('de')
				.startOf('day')
				.toDate();
			this.dataService.employeesHttpService
				.create(createData)
				.subscribe((emp) => {
					targetVacationDays.forEach((targetVacation) => {
						targetVacation.employee = emp;
						this.dataService.targetVacationDaysesService.create(targetVacation);
					});

					targetWorkingHours.forEach((targetWorking) => {
						targetWorking.employee = emp;
						this.dataService.targetWorkingHoursesService.create(targetWorking);
					});

					this.dataService.reloadEmployees();
					this.dataService.reloadTargetVacations();
					this.dataService.reloadWorkingHours();
					setTimeout(() => {
						// this.dataService.employeesService.findAll();
					}, 1000);
				});
		}
		this.dialogVisible = false;
		this.resetForm();
	}

	public onCloseDialog(): void {
		console.log('Dialog closed');
		this.dialogVisible = false;
		this.resetForm();
		this.currentSectionIndex = 0;
		if (this.dialog) {
			this.dialog.hide();
		}
	}

	resetForm() {
		this.currentSectionIndex = 0;
		this.employeeForm.reset();
		this.employeeForm.controls['workingHours'] = this.fb.array([]);
		this.employeeForm.controls['vacationEntitlement'] = this.fb.array([]);
		console.log(this.employeeForm);
	}

	private getWorkingHoursForm(currentIndex: number) {
		return this.fb.group({
			id: [''],
			monday: [0, [Validators.required]],
			tuesday: [0, [Validators.required]],
			wednesday: [0, [Validators.required]],
			thursday: [0, [Validators.required]],
			friday: [0, [Validators.required]],
			saturday: [0, [Validators.required]],
			sunday: [0, [Validators.required]],
			fromDate: [
				null,
				[
					Validators.required,
					this.dateOverlappingCheck(currentIndex, 'fromDate',this.workingHours),
				],
			],
			toDate: [null, [this.dateOverlappingCheck(currentIndex, 'toDate',this.workingHours)]],
		});
	}

	dateGreaterThanPrevious(index: number, field: string,form: any) {
		return (control: AbstractControl) => {
			const currentForm = form.at(index) as FormGroup;
			let previousForm: FormGroup | null = null;

			if (!currentForm) {
				return null;
			}

			const currentDate = control.value;
			let previousDate: Date | null = null;

			if (index > 0) {
				previousForm = form.at(index - 1) as FormGroup;

				if (!previousForm) {
					return null;
				}

				if (field === 'fromDate') {
					previousDate = previousForm.get('toDate')?.value
						? new Date(previousForm.get('toDate')?.value)
						: new Date(previousForm.get('fromDate')?.value);
				} else if (field === 'toDate') {
					const fromDate = currentForm.get('fromDate')?.value;
					previousDate = fromDate ? new Date(fromDate) : null;

				}
			} else if (index === 0) {
				if (field === 'toDate') {
					const fromDate = currentForm.get('fromDate')?.value;
					previousDate = fromDate ? new Date(fromDate) : null;
				}
			}
			if (
				currentDate &&
				previousDate &&
				new Date(currentDate) <= previousDate
			) {
				return { invalidDate: true };
			}
			return null;
		};
	}
	dateOverlappingCheck(index: number, field: string, form: any) {
		return (control: AbstractControl) => {
			const currentForm = form.at(index) as FormGroup;
			if (!currentForm) return null;

			const currentDate = control.value ? new Date(control.value) : null;

			let previousForm: FormGroup | null = null;
			let previousFromDate: Date | null = null;
			let previousToDate: Date | null = null;

			// Only check for overlap if there are previous rows
			if (index > 0) {
				previousForm = form.at(index - 1) as FormGroup;
				if (!previousForm) return null;

				// Get previous row's fromDate and toDate
				previousFromDate = previousForm.get('fromDate')?.value
					? new Date(previousForm.get('fromDate')?.value)
					: null;

				previousToDate = previousForm.get('toDate')?.value
					? new Date(previousForm.get('toDate')?.value)
					: null;

				if (currentDate && previousFromDate && previousToDate) {
					// Check overlap for `fromDate`
					if (field === 'fromDate') {
						if (currentDate >= previousFromDate && currentDate <= previousToDate) {
							return { dateOverlap: true };
						}
					}
					// Check overlap for `toDate`
					else if (field === 'toDate') {
						if (currentDate >= previousFromDate && currentDate <= previousToDate) {
							return { dateOverlap: true };
						}
					}
				}
			}
			return null;
		};
	}

	private getVacationEntitlementForm(currentIndex: number) {
		return this.fb.group({
			id: [''],
			fromDate: [
				null,
				[
					Validators.required,
					this.dateOverlappingCheck(currentIndex, 'fromDate',this.vacationEntitlement),
				],
			],
			toDate: [null, [this.dateOverlappingCheck(currentIndex, 'toDate',this.vacationEntitlement)]],
			amount: [0, Validators.required],
		});
	}

	public pushWorkingHoursForm(isValidationRequired: boolean = false) {
		const length = this.workingHours.length;
		// this.workingHours.push(this.getWorkingHoursForm(length));
		if(isValidationRequired) {
			this.workingHours.push(this.getWorkingHoursForm(length));
			return;
		}
		if(length == 0) {
			this.workingHours.push(this.getWorkingHoursForm(length));
		} else {
			const lastForm = this.workingHours.at(length-1) as FormGroup;
			if (!lastForm) {
				return;
			}
			const fromDate = lastForm.get('fromDate')?.value;
			if(fromDate && !lastForm.get('fromDate')?.errors && lastForm.get('toDate')?.value) {
				this.workingHours.push(this.getWorkingHoursForm(length));
			}
		}
	}

	public pushVacationEntitlementForm(isValidationRequired: boolean = false) {
		const length = this.vacationEntitlement.length;
		if(isValidationRequired) {
			this.vacationEntitlement.push(this.getVacationEntitlementForm(length));
			return;
		}
		if(length == 0) {
			this.vacationEntitlement.push(this.getVacationEntitlementForm(length));
		}
		else {
			const lastForm = this.vacationEntitlement.at(length-1) as FormGroup;
			if (!lastForm) {
				return;
			}
			const fromDate = lastForm.get('fromDate')?.value;
			if(fromDate && !lastForm.get('fromDate')?.errors && lastForm.get('toDate')?.value) {
				this.vacationEntitlement.push(this.getVacationEntitlementForm(length));
			}
		}
	}

	public isAddVacationEntitlementDisabled() {
		const length = this.vacationEntitlement.length;

		if (length === 0) {
			return false;
		}

		const lastForm = this.vacationEntitlement.at(length - 1) as FormGroup;
		if (!lastForm) {
			return true;
		}

		const fromDate = lastForm.get('fromDate')?.value;
		const toDate = lastForm.get('toDate')?.value;

		return !(fromDate && !lastForm.get('fromDate')?.errors && toDate && !lastForm.get('toDate')?.errors);
	}

	public isAddWorkingHoursDisabled() {
		const length = this.workingHours.length;

		if (length === 0) {
			return false;
		}

		const lastForm = this.workingHours.at(length - 1) as FormGroup;
		if (!lastForm) {
			return true;
		}

		const fromDate = lastForm.get('fromDate')?.value;
		const toDate = lastForm.get('toDate')?.value;

		return !(fromDate && !lastForm.get('fromDate')?.errors && toDate && !lastForm.get('toDate')?.errors);
	}

	public removeWorkingHoursForm(index: number) {
		const workingHours: WorkingHours = this.workingHours.controls[index].value;
		if (workingHours.id) {
			this.dataService.targetWorkingHoursesService.delete({
				id: workingHours.id,
				from: workingHours.fromDate,
				to: workingHours.toDate,
				monday: workingHours.monday,
				tuesday: workingHours.tuesday,
				wednesday: workingHours.wednesday,
				thursday: workingHours.thursday,
				friday: workingHours.friday,
				saturday: workingHours.saturday,
				sunday: workingHours.sunday,
			});
		}
		this.workingHours.removeAt(index);
	}

	public removeVacationEntitlementForm(index: number) {
		const vacationEntitlement: VacationEntitlement =
			this.vacationEntitlement.controls[index].value;
		if (vacationEntitlement.id) {
			this.dataService.targetVacationDaysesService.delete({
				id: vacationEntitlement.id,
				amount: vacationEntitlement.amount,
				from: vacationEntitlement.fromDate,
				to: vacationEntitlement.toDate,
			});
		}
		this.vacationEntitlement.removeAt(index);
	}

	public dateRangeShouldNotCoincideWithPreviousDateRange(
		index: number,
		form: FormArray,
		dateKey: 'fromDate' | 'toDate'
	) {
		return (abstractControl: AbstractControl) => {
			// const previousRange = form.at(index - 1)?.get('dateRange')?.value as [Date, Date];
			// const currentRange = abstractControl.value as [Date, Date];

			const previousFromDate = form.at(index - 1)?.get('fromDate')
				?.value as Date;
			const previousToDate = form.at(index - 1)?.get('toDate')?.value as Date;
			const currentDate = abstractControl.value as Date;

			if (!index || !form || !previousFromDate || !previousToDate) {
				return null;
			}

			if (dateKey === 'fromDate') {
				console.log('dateKey: ', dateKey);
				return previousToDate.getTime() < currentDate.getTime()
					? null
					: { coincidingRange: true };
			}

			if (dateKey === 'toDate' && currentDate) {
				const currentFromDate = form.at(index)?.get('fromDate')?.value as Date;
				if (!currentFromDate) {
					return null;
				}
				console.log('currentFromDate: ', currentFromDate.getTime());
				console.log('currentDate.getTime(): ', currentDate.getTime());
				return currentFromDate.getTime() <= currentDate.getTime()
					? null
					: { coincidingRange: true };
			}

			return null;

			// if (!index || !form || !previousRange) {
			// 	return null;
			// }
			// return previousRange[1].getTime() < currentRange[0].getTime() ? null : {coincidingRange: true}
		};
	}

	public onEmployeeDelete(employee: Employee) {
		// Store the employee to be deleted
		this.employeeToDelete = employee;
		
		// Set the confirmation message
		const contentElement = document.getElementById('confirmDialogContent');
		if (contentElement) {
			contentElement.innerHTML = `Möchten Sie wirklich den Mitarbeiter "${employee.firstname} ${employee.lastname}" archivieren?`;
		}
		
		// Show the confirmation dialog
		this.confirmDialog.show();
	}

	public onConfirmDelete() {
		if (this.employeeToDelete) {
			// Proceed with deletion after confirmation
			this.employeeToDelete.isArchived = true;
			this.dataService.employeesService.update(this.employeeToDelete);
			this.dataService.reloadTargetVacations();
			this.dataService.reloadWorkingHours();
			this.dataService.reloadEmployees();
			
			// Hide the dialog and clear the reference
			this.confirmDialog.hide();
			this.employeeToDelete = null;
		}
	}

	public onCancelDelete() {
		// Just hide the dialog and clear the reference
		this.confirmDialog.hide();
		this.employeeToDelete = null;
	}

	public onEmployeeUnarchive(employee: Employee) {
		employee.isArchived = false;
		this.dataService.employeesService.update(employee);
		this.dataService.reloadEmployees();
		this.dataService.reloadTargetVacations();
		this.dataService.reloadWorkingHours();
	}

	async onFileChange(evt: any) {
		const target: DataTransfer = <DataTransfer>evt.target;
		if (target.files.length !== 1) throw new Error('Cannot use multiple files');

		const formData = new FormData();
		formData.append('file', target.files[0]);

		await this.interactionsService.employeesService.bulkCreate(formData);

		this.dataService.reloadEmployees();
		this.dataService.reloadTargetVacations();
		this.dataService.reloadWorkingHours();
	}

	async extractData(data: any[]) {
		// Assuming the first row contains column headers e.g., Name and Email
		this.fileData = data
			.slice(1)
			.map((row) => ({
				firstName: row[0],
				lastName: row[1],
				role: row[2],
				email: row[3],
				birthday: row[4],
				state: row[5],
				targetVacationPerYear: row[6],
				takenThisYear: row[7],
				lastYearVacation: row[8],
				startDate: row[11],
			}))
			.filter((row) => row.firstName && row.lastName && row.role && row.email);
		console.log('this.fileData', this.fileData);

		const createPromises = this.fileData.map((user: any) => {
			let userData = {
				firstname: user.firstName,
				lastname: user.lastName,
				email: user.email,
				isArchived: false,
				role: roleTypes[user.role],
				birthday: moment(this.convertExcelDate(user.birthday), 'DD.MM.YYYY')
					.locale('de')
					.utc(true)
					.startOf('day')
					.toDate(),
				outlet: 'Kassel',
				stateToApply: user.state,
				startDate: moment(this.convertExcelDate(user.startDate), 'DD.MM.YYYY')
					.locale('de')
					.utc(true)
					.startOf('day')
					.toDate(),
				// targetVacationDayses: [
				// 	{
				// 		id: uuid(),
				// 		amount: ((user.targetVacationPerYear + user.laslastYearVacation) - user.takenThisYear),
				// 		from: moment().startOf('year').toDate(),
				// 		to: moment().endOf('year').toDate()
				// 	}
				// ],
			};
			return this.dataService.employeesService.create(userData);
		});

		try {
			await Promise.all(createPromises).then(() => {});
		} catch (error) {
			console.error('Error creating employees:', error);
		}
	}

	convertExcelDate(excelDate: number): Date {
		// Excel-Datumsbasis ist der 30. Dezember 1899
		const excelBaseDate = new Date(1899, 11, 30); // Monat ist nullbasiert, daher 11 für Dezember
		const actualDate = new Date(
			excelBaseDate.getTime() + excelDate * 24 * 60 * 60 * 1000
		);
		return actualDate;
	}

	addNewTargetVacationDays() {
		if (this.fileData) {
			console.log('FILE DATA', this.fileData);
			this.fileData.forEach((user: any) => {
				let employees = this.dataService.employeesCacheService.employees;
				let employee = employees.find((emp) => emp.email === user.email);
				if (employee) {
					this.dataService.targetVacationDaysesService.create({
						amount:
							user.targetVacationPerYear +
							user.lastYearVacation -
							user.takenThisYear,
						from: moment().startOf('year').toDate(),
						to: moment().endOf('year').toDate(),
						employee: employee,
					});
				}
			});
			this.dataService.targetVacationDaysesService.findAll();
			this.dataService.reloadEmployees();
			setTimeout(() => {
				this.refreshTable(this.dataService.employeesCacheService.employees);
				this.fileData = null;
			}, 2000);
		}
	}

	changeSection(index: number) {
		this.currentSectionIndex = index;
		this.sectionName =
			index === 0
				? 'Mitarbeiter Personaldaten'
				: index === 1
				? 'Mitarbeiter Urlaubsanspruch'
				: 'Mitarbeiter Arbeitszeiten';
	}

	dateAccessor(field: string, data: any, column: any) {
		// let date = new Date(data[field]);
		// console.log("DATE", new Intl.DateTimeFormat('de-DE').format(date))
		// return new Intl.DateTimeFormat('de-DE').format(date);
	}

	@HostListener('document:click', ['$event.target'])
	public handleOutsideClick(target: HTMLElement) {
		if (
			this.dialogVisible &&
			!target.closest('.e-dlg') && 
			!target.closest('.custom-tooltip') && 
			!target.closest('.e-dropdown-popup') && 
			!target.closest('.e-input-group') && 
			!target.closest('.e-dropdownlist') && 
			!target.closest('.e-list-item') && 
			!target.closest('.e-popup') && 
			!target.closest('.e-calendar') && 
			!target.closest('.e-datepicker') && 
			!target.closest('.e-datepicker-wrapper') && 
			!target.closest('.e-datepicker-popup') && 
			!target.closest('.e-calendar-day') && 
			!target.closest('.e-calendar-month') && 
			!target.closest('.e-calendar-year') && 
			!target.closest('.e-btn-icon') && 
			!target.closest('.e-datepicker-header') 
		) {
			this.onCloseDialog();
		}
	}

	ngOnDestroy(): void {
		this.$destroy.next();
		this.$destroy.complete();
	}

	protected readonly roleTypes = roleTypes;
	protected readonly isMobile = isMobile;
}
