<div class="App overlay" (click)="close()">
	<div class="AbsenceDialog" (click)="$event.stopPropagation()">
		<div class="heading">
			<h2><strong>Abwesenheit beantragen</strong></h2>
		</div>

		<form [formGroup]="form">

			<div class="field">
				<ejs-dropdownlist #absenceTypeDropdown
					[placeholder]="'Abwesenheitsart'"
					[(value)]="absenceType"
					[dataSource]="types"
					[fields]="dropdownFields"
					(change)='absenceTypeOnChange($event)'
					class="fullWidth"
					formControlName="type"
					[itemTemplate]="itemTemplate"
					[enableRtl]="false">
				</ejs-dropdownlist>
				<div *ngIf="getControl('type').errors as errors">
					<div *ngIf="errors['required'] && getControl('type').touched" class="e-error">
						Dieses Feld ist erforderlich.
					</div>
				</div>
			</div>

			<ng-template #itemTemplate let-data>
				<div [class.disabled-item]="isTypeDisabled(data.value)" [title]="getTypeTooltip(data.value)">
					{{data.text}}
				</div>
			</ng-template>

			<div class="fullRow">
				<div class="field halfWidth">
					<ejs-datepicker [placeholder]="'Von'" format="dd.MM.yyyy" locale="de" [firstDayOfWeek]="1"
													formControlName="from"></ejs-datepicker>
					<div *ngIf="getControl('from').errors as errors">
						<div *ngIf="errors['required'] && getControl('from').touched" class="e-error">
							Dieses Feld ist erforderlich.
						</div>
					</div>
				</div>

				<div class="field halfWidth">
					<ejs-datepicker [placeholder]="'Bis'" format="dd.MM.yyyy" locale="de" [firstDayOfWeek]="1"
													formControlName="to"></ejs-datepicker>
					<div *ngIf="getControl('to').errors as errors">
						<div *ngIf="errors['required'] && getControl('to').touched" class="e-error">
							Dieses Feld ist erforderlich.
						</div>
						<div *ngIf="errors['range'] && getControl('to').touched" class="e-error">
							Die Endzeit kann nicht vor der Startzeit liegen.
						</div>
					</div>
				</div>
			</div>

			<div *ngIf="absenceTypeDropdown.value !== 'Elternzeit' && from.toISOString().slice(0,10) === to.toISOString().slice(0,10)" class="field">
				<ejs-checkbox [label]="'Halber Tag Abwesenheit'" formControlName="halfDay"></ejs-checkbox>
				<div *ngIf="getControl('halfDay').errors as errors">
					<div *ngIf="errors['required'] && getControl('halfDay').touched" class="e-error">
						Dieses Feld ist erforderlich.
					</div>
				</div>
			</div>

			<div *ngIf="absenceType === 'Bezahlter Urlaub'" class="field">
				<ejs-dropdownlist [placeholder]="'Vertretung wählen'" [dataSource]="filteredEmployees"
													[fields]="{ text: 'name', value: 'id' }"
													formControlName="substitution" class="fullWidth"></ejs-dropdownlist>
				<div *ngIf="getControl('substitution').errors as errors">
					<div *ngIf="errors['required'] && getControl('substitution').touched" class="e-error">
						Dieses Feld ist erforderlich.
					</div>
				</div>
			</div>

			<div class="field">
				<ejs-textbox #textarea class="fullWidth" [placeholder]="'Kommentar'" [floatLabelType]="'Auto'"
										 [cssClass]="'e-'+''"
										 rows="2" formControlName="comment" [multiline]="true"></ejs-textbox>
				<div *ngIf="getControl('comment').errors as errors">
					<div *ngIf="errors['required'] && getControl('comment').touched" class="e-error">
						Dieses Feld ist erforderlich.
					</div>
				</div>
			</div>

			<div class="fullRow">
				<button ejs-button [cssClass]="'e-'+''" [isPrimary]="+'0' == 1" (click)="close()">
					Abbrechen
				</button>
				<button [disabled]="form.invalid" ejs-button [cssClass]="'e-'+''" [isPrimary]="+'1' == 1" (click)="gotoNext()">
					Speichern
				</button>
			</div>
		</form>
	</div>
</div>
