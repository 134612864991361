import {
	Component,
	EventEmitter,
	Input,
	Output,
	SimpleChanges,
	OnInit,
	ViewChild,
	ChangeDetectorRef, AfterViewInit
} from '@angular/core';
import {
	AbstractControl,
	FormBuilder,
	FormControl,
	FormGroup,
	ValidationErrors,
	ValidatorFn,
	Validators
} from '@angular/forms';
import {Abscence} from 'src/app/entities/abscences/models/abscence.model'
import {Employee} from "../../entities/employees/models/employee.model";
import {AuthService} from "../../shared/services/auth.service";
import {ToastUtility} from '@syncfusion/ej2-notifications';

type AbwesenheitsartType = 'Bezahlter Urlaub' | 'Krankheit'
import {Toast, ToastComponent, ToastModel} from '@syncfusion/ej2-angular-notifications';
import {DropDownListComponent} from "@syncfusion/ej2-angular-dropdowns";
import {DataService} from "../../shared/services/data.service";
import moment from "moment";
import { TimeService } from 'src/app/shared/services/time.service';
import { TargetWorkingHours } from 'src/app/entities/target-working-hourses/models/target-working-hours.model';

@Component({
	selector: 'leaveRequestDialog',
	templateUrl: './leaveRequestDialog.component.html',
	styleUrls: ['./leaveRequestDialog.component.scss']
})
export class LeaveRequestDialogComponent implements OnInit, AfterViewInit {

	@Output() closed: EventEmitter<string> = new EventEmitter<string>()
	@Output() next: EventEmitter<any> = new EventEmitter<any>()
	@Input() abscence: Abscence | null;
	@Input() publicHolidays: any;
	form: FormGroup;
	currentEmployee: Employee;
	empolyeeList: any[];
	filteredEmployees: any[];
	public toastObj?: ToastComponent
	absenceType: string = '';
	@ViewChild('absenceTypeDropdown') absenceTypeDropdown: DropDownListComponent;
	types: any[] = [];
	disabledTypes: string[] = [];
	typeTooltips: { [key: string]: string } = {};
	dropdownFields: object = { text: 'text', value: 'value' };


	// type?: AbwesenheitsartType;
	get type() {
		return this.form.controls['type'].value;
	}

	set type(value: AbwesenheitsartType) {
		this.form.controls['type'].setValue(value);
	}

	// from?: Date
	get from() {
		return this.form?.controls['from'].value;
	}

	set from(value: Date) {
		this.form.controls['from'].setValue(value);
	}

	// to?: Date
	get to() {
		return this.form.controls['to'].value;
	}

	set to(value: Date) {
		this.form.controls['to'].setValue(value);
	}

	// halfDay?: boolean = false
	get halfDay() {
		return this.form.controls['halfDay'].value;
	}

	set halfDay(value: boolean) {
		this.form.controls['halfDay'].setValue(value);
	}

	// substitution?: string
	get substitution() {
		return this.form.controls['substitution'].value;
	}

	set substitution(value: string) {
		this.form.controls['substitution'].setValue(value);
	}

	// comment?: string = ''
	get comment() {
		return this.form.controls['comment'].value;
	}

	set comment(value: string) {
		this.form.controls['comment'].setValue(value);
	}

	getControl(controlName: string) {
		return this.form.controls[controlName] as FormControl
	}

	constructor(private fb: FormBuilder, private authService: AuthService, private dataService: DataService, private cdr: ChangeDetectorRef, private readonly timeService: TimeService) {
		this.form = this.fb.group({
			type: ['', [Validators.required]],
			from: [new Date(), [Validators.required]],
			to: [new Date(), [Validators.required, this.isGreaterThanValidator()]],
			halfDay: [false, [Validators.required]],
			substitution: ['',],
			comment: ['']
		})
	}

	ngOnInit() {
		// Define all possible types with proper format for dropdown
		this.types = [
			{ text: 'Bezahlter Urlaub', value: 'Bezahlter Urlaub' },
			{ text: 'Elternzeit', value: 'Elternzeit' },
			{ text: 'Gleitzeit', value: 'Gleitzeit' },
			{ text: 'Krankheit', value: 'Krankheit' },
			{ text: 'Sonderurlaub', value: 'Sonderurlaub' }
		];

		// Set up disabled types and tooltips for non-admin users
		if (!this.authService.isAdmin() && !this.authService.isBranchAdmin()) {
			this.disabledTypes = ['Krankheit'];
			this.typeTooltips = {
				'Krankheit': 'Nur Administratoren können Krankheit eintragen',
				'Sonderurlaub': 'Nur Administratoren können Sonderurlaub eintragen'
			};
		}

		this.getCurrentEmployee();
	}

	ngAfterViewInit() {

		this.absenceType = '';
		this.empolyeeList = ['Tom', 'John']
		this.dataService.employeesService.updated$.subscribe(() => {
			this.empolyeeList = this.dataService.employeesCacheService.employees
			// .filter((employee: any) => employee.id !== this.currentEmployee.id)
			// .map((employee: any) => {
			// 	return {
			// 		id: employee.id,
			// 		name: `${employee.firstname} ${employee.lastname}`
			// 	}
			// });
		});

		this.dataService.employeesService.employees$.subscribe((employees) => {
			this.empolyeeList = employees;
			this.filteredEmployees = this.empolyeeList
				.filter((employee: any) => employee.id !== this.currentEmployee.id && !employee.isArchived)
				.map((employee: any) => {
					return {
						id: employee.id,
						name: `${employee.firstname} ${employee.lastname}`
					}
				});
		})
	}

	absenceTypeOnChange(event: any): void {
		if (!event || !event.value) return;
		const value = event.value;
		const substitutionControl = this.form.get('substitution');

		if (this.isTypeDisabled(value)) {
			setTimeout(() => {
				if (this.absenceTypeDropdown) {
					this.absenceTypeDropdown.value = null;
					const typeControl = this.form.get('type');
					if (typeControl) {
						typeControl.setValue(null);
					}
				}
			}, 200);

			let toast: ToastModel = ToastUtility.show(this.getTypeTooltip(value), 'Error', 5000);
			return;
		}

		if (value === 'Bezahlter Urlaub') {
			substitutionControl?.setValidators(this.substitutionRequiredValidator(value));
		} else {
			substitutionControl?.clearValidators();
			substitutionControl?.reset();
		}
		substitutionControl?.updateValueAndValidity();
	}

	getCurrentEmployee() {
		if (!this.currentEmployee) {
			this.currentEmployee = this.authService.selectedEmployee;
			console.log('Current employee', this.currentEmployee);
		}
		// Subscribe to current user observable to get initial employee data
		this.authService.selectedUser$.subscribe((employee) => {
			this.currentEmployee = employee;
			this.filteredEmployees = this.empolyeeList
				.filter((employee: any) => employee.id !== this.currentEmployee.id && !employee.isArchived)
				.map((employee: any) => {
					return {
						id: employee.id,
						name: `${employee.firstname} ${employee.lastname}`
					}
				});
		});

	}

	substitutionRequiredValidator(absenceType: string): ValidatorFn {
		return (control: AbstractControl): ValidationErrors | null => {
			const substitution = control.value;
			if (absenceType === 'Bezahlter Urlaub' && (!substitution || substitution.trim() === '')) {
				return { substitutionRequired: true };
			}
			return null;
		};
	}

	handleLeaveTypeSelection(ev) {
		const selectedType = ev.itemData.value;

		// Check if the selected type is disabled
		if (this.isTypeDisabled(selectedType)) {
			setTimeout(() => {
				this.absenceTypeDropdown.value = null;
				this.form.get('type')?.setValue(null);
			}, 200);
			let toast: ToastModel = ToastUtility.show(this.getTypeTooltip(selectedType), 'Error', 5000);
			return false;
		}

		this.absenceType = selectedType;
		if (selectedType === 'Gleitzeit') {
			// Convert 'from' and 'to' dates to moment objects
			const from = moment(this.from).startOf('day');
			const to = moment(this.to).startOf('day');

			let totalHoursRequired = 0;

			for (let m = moment(from); m.isBefore(to) || m.isSame(to); m.add(1, 'days')) {
				// Get the target working hours for the current month
				const targetWorkingHours =  this.currentEmployee.targetWorkingHourses &&
					this.currentEmployee.targetWorkingHourses.find(hours =>
						m.isSameOrAfter(moment(hours.from)) && m.isSameOrBefore(moment(hours.to))
					);

				if (targetWorkingHours) {
					// Get the target working hours for the current day
					const day = m.locale('en').format('dddd').toLowerCase();
					totalHoursRequired += Number(targetWorkingHours[day]);
				}
			}

			if (this.timeService.userOvertime && this.timeService.userOvertime >= totalHoursRequired) {
				this.timeService.userOvertime -= totalHoursRequired;
				console.log(`GLE leave type selected. ${totalHoursRequired} hours subtracted from overtime.`);
				return true;
			} else {
				setTimeout(() => {
					this.absenceTypeDropdown.value = null;
				}, 200);
				let toast: ToastModel = ToastUtility.show('Sie können leider nicht Gleitzeit als Urlaubsart wählen. Nicht genügend Überstunden.', 'Error', 5000);
				return false;
			}
		}
		return true;
	}


	ngOnChanges(changes: SimpleChanges): void {
		if (changes['abscence'] && this['abscence']) {
			this.absenceType = this['abscence'].type;
			this.type = this['abscence'].type as AbwesenheitsartType;
			this.from = this['abscence'].from;
			this.to = this['abscence'].to;
			this.halfDay = this['abscence'].halfDay;
			this.substitution = this['abscence'].substitution;
			this.comment = this['abscence'].comment;
		}
	}

	close() {

		this.closed.emit('closed')
	}

	gotoNext() {
		// Check if the user is an employee and trying to edit an absence
		if (this.authService.currentEmployee.role === 'employee' && this.abscence) {
			// Check if the employee is the owner of the absence
			if (this.abscence.employee && this.abscence.employee.id === this.authService.currentEmployee.id) {
				// Check if the absence was created within the last 3 days
				const createdAt = moment(this.abscence.createdAt).startOf('day');
				const threesDaysAgo = moment().subtract(3, 'days').startOf('day');
				
				if (createdAt.isBefore(threesDaysAgo)) {
					let toast: ToastModel = ToastUtility.show(
						'Mitarbeiter können nur Abwesenheiten bearbeiten, die in den letzten 3 Tagen erstellt wurden.',
						'Error',
						5000
					);
					return;
				}
			} else if (this.abscence.employee && this.abscence.employee.id !== this.authService.currentEmployee.id) {
				// Show error message if employee is trying to edit someone else's absence
				let toast: ToastModel = ToastUtility.show(
					'Mitarbeiter können nur ihre eigenen Abwesenheiten bearbeiten.',
					'Error',
					5000
				);
				return;
			}
		}

		if (!this.type || !this.from || !this.to) {
			return;
		}

		if (!this.handleLeaveTypeSelection({ itemData: { value: this.type } })) return;

		const leaveRequests: Partial<Abscence>[] = [];
		let currentFrom = new Date(this.from);
		let segmentStart = new Date(this.from); // Tracks the start of a valid segment

		const pushLeaveRequest = (from: Date, to: Date) => {
			leaveRequests.push({
				type: this.type,
				from: new Date(from),
				to: new Date(to),
				halfDay: this.halfDay,
				substitution: this.substitution,
				comment: this.comment,
			});
		};

		if(this.isDateOverlapping(this.currentEmployee.abscences || [], this.from, this.to) && this.from != this.abscence?.from && this.to != this.abscence?.to){
			let toast: ToastModel = ToastUtility.show(
				'Der angegebene Datumsbereich überschneidet sich mit einem bestehenden Urlaub.',
				'Error',
				10000
			);
			return;
		}

		let isFullsegmentHoliday = true;

		while (currentFrom <= this.to) {
			const isNonWorking = this.isNonWorkingDay(this.currentEmployee.targetWorkingHourses || [], currentFrom, currentFrom);
			const isHoliday = this.getHolidaysBetween(currentFrom, currentFrom) > 0;

			if (isNonWorking || isHoliday) {
				if (currentFrom > segmentStart) {
					const segmentEnd = new Date(currentFrom);
					segmentEnd.setDate(segmentEnd.getDate() - 1);
					pushLeaveRequest(segmentStart, segmentEnd);
				}
				currentFrom.setDate(currentFrom.getDate() + 1);
				segmentStart = new Date(currentFrom);
			} else {
				isFullsegmentHoliday = false
				currentFrom.setDate(currentFrom.getDate() + 1);
			}
		}

		if (isFullsegmentHoliday) {
			let toast: ToastModel = ToastUtility.show(
				'Der angegebene Datumsbereich überschneidet sich mit einem bestehenden Urlaub.',
				'Error',
				10000
			);
			return;
		}

		if (segmentStart <= this.to) {
			pushLeaveRequest(segmentStart, this.to);
		}
		this.next.emit({ leaveRequests, currentEmployee: this.currentEmployee });
	}

	isGreaterThanValidator() {
		return (control: AbstractControl): ValidationErrors | null => {
			const to = control.value;
			const from = this.from;
			return (to >= from) ? null : {range: true};
		}
	}

	isDateOverlapping(leaves: Abscence[], fromDate: Date, toDate: Date): boolean {
		const normalizeDate = (date: Date) =>
			new Date(date.getFullYear(), date.getMonth(), date.getDate()).toISOString().split('T')[0];

		const from = normalizeDate(fromDate);
		const to = normalizeDate(toDate);

		return leaves.some(leave => {
			const leaveFrom = normalizeDate(new Date(leave.from));
			const leaveTo = normalizeDate(new Date(leave.to));

			const isOverlapping =
				(from >= leaveFrom && from <= leaveTo) ||
				(to >= leaveFrom && to <= leaveTo) ||
				(leaveFrom >= from && leaveFrom <= to);

			return isOverlapping;
		});
	}

	isNonWorkingDay(targetWorkingHourses: TargetWorkingHours[], fromDate: Date, toDate: Date): boolean {
		const normalizeDate = (date: Date) =>
			new Date(date.getFullYear(), date.getMonth(), date.getDate());

		const dayMap = [
			'sunday',
			'monday',
			'tuesday',
			'wednesday',
			'thursday',
			'friday',
			'saturday',
		];

		const from = normalizeDate(fromDate);
		const to = normalizeDate(toDate);

		let currentDate = from;

		while (currentDate <= to) {
			const dayOfWeek = currentDate.getDay();

			for (const config of targetWorkingHourses) {
				const configFrom = normalizeDate(new Date(config.from));
				const configTo = config.to ? normalizeDate(new Date(config.to)) : normalizeDate(new Date(new Date().getFullYear(), 11, 31));
				if (currentDate >= configFrom && currentDate <= configTo) {
					const hours = parseInt(config[dayMap[dayOfWeek]] || '0', 10);
					if (hours === 0) {
						return true;
					}
				}
			}

			currentDate.setDate(currentDate.getDate() + 1);
		}

		return false;
	}

	getHolidaysBetween(fromDate: Date, toDate: Date): number {
		let holidaysCount = 0;
		let currentDate = new Date(fromDate);
		currentDate.setHours(1);
		while (currentDate <= toDate) {
			const isHoliday = this.publicHolidays?.find((holiday: any) => {
				return holiday.from.toDateString() === currentDate.toDateString();
			});
			if (isHoliday) {
				holidaysCount++;
			}
			currentDate.setDate(currentDate.getDate() + 1);
		}
		return holidaysCount;
	}

	protected readonly event = event;
	protected readonly Event = Event;

	// Add method to check if a type is disabled
	isTypeDisabled(type: string): boolean {
		return this.disabledTypes.includes(type);
	}

	// Add method to get tooltip for a type
	getTypeTooltip(type: string): string {
		return this.typeTooltips[type] || '';
	}
}
