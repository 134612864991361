import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { ItemModel, MenuEventArgs } from '@syncfusion/ej2-angular-splitbuttons';
import { DataService } from 'src/app/shared/services/data.service';
import { InteractionsService } from 'src/app/shared/services/interactions.service';

@Component({
	selector: 'app-notifiction',
	templateUrl: './notifiction.component.html',
	styleUrls: ['./notifiction.component.scss'],
})
export class NotifictionComponent implements OnInit {
	notificationCount: number = 0;
	items: ItemModel[] = [];
	visible: boolean = false;
	selectedNotification: any = {};
	@ViewChild('ejDialog') ejDialog: DialogComponent | undefined;
	substitutionRequest:any = [];
	private isSelecting: boolean = false;

	public constructor(
		public readonly dataService: DataService,
		public readonly interactionsService: InteractionsService,
	) {}

	ngOnInit() {
		this.dataService.abscencesService.updated$.subscribe(()=>{
			this.substitutionRequest = this.dataService.abscencesCacheService.substitutionRequest;
			this.notificationCount = this.dataService.abscencesCacheService.substitutionRequest.length;
			const sortedData = this.dataService.abscencesCacheService.substitutionRequest.sort((a:any, b:any) => new Date(a.from).getTime() - new Date(b.from).getTime());
			this.items = [];
			for(let request of sortedData){
				this.items.push({
					id: request.id,
					text: ` Sie wurden als Ersatz für ${request?.employee?.firstname} ${request?.employee?.lastname} hinzugefügt.`
				});
			}
		})
	}

	public select(args: MenuEventArgs) {
		this.isSelecting = true;
		const request = this.substitutionRequest.find((request:any) => request.id == args.item.id);
		this.selectedNotification = request;
		this.visible = true;

		// Reset the flag after a short delay
		setTimeout(() => {
			this.isSelecting = false;
		}, 100);
	}

	confirmRequest() {
		this.interactionsService.abscencesService.updateSubstitutionRequest({...this.selectedNotification,substitutionStatus:'accepted'});
		this.visible = false;
	}

	rejectRequest() {
		this.interactionsService.abscencesService.updateSubstitutionRequest({...this.selectedNotification,substitutionStatus:'rejected'});
		this.visible = false;
	}

	getToDate(date){
		if(date){
			const currentDate = new Date(date);
			return currentDate
		}else{
			return
		}
	}

	@HostListener('document:click', ['$event.target'])
	public handleOutsideClick(target: HTMLElement) {
		if (this.isSelecting) {
			return;
		}

		if (
			this.visible &&
			!target.closest('.e-dlg') &&
			!target.closest('.custom-tooltip') &&
			!target.closest('.e-dropdown-popup') &&
			!target.closest('.e-input-group') &&
			!target.closest('.e-dropdownlist') &&
			!target.closest('.e-list-item') &&
			!target.closest('.e-popup') &&
			!target.closest('.e-calendar') &&
			!target.closest('.e-datepicker') &&
			!target.closest('.e-datepicker-wrapper') &&
			!target.closest('.e-datepicker-popup') &&
			!target.closest('.e-calendar-day') &&
			!target.closest('.e-calendar-month') &&
			!target.closest('.e-calendar-year') &&
			!target.closest('.e-btn-icon') &&
			!target.closest('.e-datepicker-header') &&
			!target.closest('.notification-wrapper') &&
			!target.closest('.e-dropdown-btn') &&
			!target.closest('.e-menu-item') &&
			!target.closest('.notification-container') &&
			!target.closest('.notification-badge') &&
			!target.closest('.e-dialog') &&
			!target.closest('.e-dialog-content') &&
			!target.closest('.e-footer-content')
		) {
			this.visible = false;
		}
	}
}
