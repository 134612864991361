import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'

import {ZeiterfassungComponent} from './pages/zeiterfassung/zeiterfassung.component';
import {AbwesenheitsuebersichtComponent} from './pages/abwesenheitsuebersicht/abwesenheitsuebersicht.component';
import {KeycloakGuard} from './core/guards/keycloak.guard'
import {EmployeesOverviewPageComponent} from './pages/employees-overview-page/employees-overview-page.component';
import {YearlyOverviewComponent} from "./pages/yearly-overview/yearly-overview.component";
import { roleGuard } from './core/guards/role.guard';

const routes: Routes = [
	{
		path: '', canActivate: [KeycloakGuard],
		component: ZeiterfassungComponent
	},
	{
		path: 'abwesenheitsuebersicht', canActivate: [KeycloakGuard],
		component: AbwesenheitsuebersichtComponent
	},
	{
		path: 'employees', canActivate: [KeycloakGuard,roleGuard],
		component: EmployeesOverviewPageComponent,
		data: { permissionKey: 'employees' }
	},
	{
		path: 'yearly', canActivate: [KeycloakGuard,roleGuard],
		component: YearlyOverviewComponent,
		data: { permissionKey: 'yearly' }
	},
]

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule]
})
export class AppRoutingModule {
}
