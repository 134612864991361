import { Injectable } from '@angular/core';

import { AuthService } from 'src/app/shared/services/auth.service';
import { TargetWorkingHoursesCacheService } from 'src/app/entities/target-working-hourses/services/target-working-hourses-cache.service';
import { TargetVacationDaysesService } from 'src/app/entities/target-vacation-dayses/services/target-vacation-dayses.service';
import { TargetVacationDaysesCacheService } from 'src/app/entities/target-vacation-dayses/services/target-vacation-dayses-cache.service';
import { AbscencesService } from 'src/app/entities/abscences/services/abscences.service';
import { AbscencesCacheService } from 'src/app/entities/abscences/services/abscences-cache.service';

@Injectable()
export class ContingentService {
  public leaveEntitlement: number = 0;
  public carryOver: number = 0;
  public taken: number = 0;
  public planed: number = 0;
  public left: number = 0;
  public abscencesByType = {};

  public constructor(
    private readonly authService: AuthService,
    private readonly targetWorkingHoursesCacheService: TargetWorkingHoursesCacheService,
    private readonly targetVacationDaysesService: TargetVacationDaysesService,
    private readonly targetVacationDaysesCacheService: TargetVacationDaysesCacheService,
    private readonly abscencesService: AbscencesService,
    private readonly abscencesCacheService: AbscencesCacheService
  ) {
  }

  public init() {
    this.targetVacationDaysesService.findAll();
    const sub1 = this.targetVacationDaysesService.updated$.subscribe(() => {
      this.abscencesService.findAll();
      const sub2 = this.abscencesService.updated$.subscribe(() => {
        this.calculate();
        sub2.unsubscribe();
      });
      sub1.unsubscribe();
    });
  }

  public calculate(year = new Date().getFullYear()) {
    const selectedEmployee = this.authService.selectedEmployee;
    const targetVacationDayses = this.targetVacationDaysesCacheService.targetvacationdayses.filter(
      (tvd) => tvd.employee?.id === selectedEmployee.id,
    );
    const allVacationDays = this.abscencesCacheService.abscences.filter((a) => (a.employee?.id === selectedEmployee.id && (a.from.getFullYear() === year || a.from.getFullYear() === year)));
    const vacationDays = allVacationDays.filter((a) => a.type === 'Bezahlter Urlaub');
    const prevVacationDays = this.abscencesCacheService.abscences.filter((a) => (a.employee?.id === selectedEmployee.id && a.from.getFullYear() === year - 1 && a.type === 'Bezahlter Urlaub'));
    this.abscencesByType = this.calculateDaysByType(allVacationDays, year);

    const currentYear = year;
    const lastYear = currentYear - 1;
    const now = new Date();

    // Berechnung der Urlaubsansprüche für das aktuelle Jahr
    let leaveEntitlement = 0;

    targetVacationDayses.forEach((tvd) => {
      let currentDate = new Date(tvd.from);
      const endDate = tvd.to ? new Date(tvd.to) : new Date(year, 11, 31);

      while (currentDate.getFullYear() <= endDate.getFullYear()) {
        const year = currentDate.getFullYear();
        const daysInYear = this.daysForYear(year);

        const yearStart = new Date(year, 0, 1);
        const yearEnd = new Date(year, 11, 31);

        const fromDate = currentDate > yearStart ? currentDate : yearStart;
        const toDate = endDate < yearEnd ? endDate : yearEnd;

        const days = (toDate.getTime() - fromDate.getTime()) / (1000 * 60 * 60 * 24) + 1;

        if (year === currentYear) {
          const entitlement = (tvd.amount / daysInYear) * days;
          leaveEntitlement += entitlement;
        }

        currentDate = new Date(year + 1, 0, 1);
      }
    });

    leaveEntitlement = Math.round(leaveEntitlement);

    // Berechnung der genommenen und geplanten Urlaubstage für das aktuelle Jahr
    let taken = 0;
    let planed = 0;
    let requested = 0;

    vacationDays.forEach((vacation) => {
      const vacationStart = new Date(vacation.from);
      const vacationEnd = new Date(vacation.to);

      let vacationDaysCount =
      (vacationEnd.getTime() - vacationStart.getTime()) / (1000 * 60 * 60 * 24) + 1;

      if(vacation.halfDay){
        vacationDaysCount = 0.5
      }

      const isRequested = vacation.substitutionStatus === 'requested';
      
      if (now >= vacationStart && now <= vacationEnd) {
        // Urlaub aktuell
        if (!isRequested) {
          taken += vacationDaysCount;
        } else {
          requested += vacationDaysCount;
        }
      } else if (now > vacationEnd) {
        // Urlaub in der Vergangenheit
        if (!isRequested) {
          taken += vacationDaysCount;
        } else {
          requested += vacationDaysCount;
        }
      } else if (now < vacationStart) {
        // Urlaub in der Zukunft
        if (!isRequested) {
          planed += vacationDaysCount;
        } else {
          requested += vacationDaysCount;
        }
      }
    });

    // Berechnung des Übertrags aus dem Vorjahr
    let carryOver = 0;

    targetVacationDayses.forEach((tvd) => {
      let currentDate = new Date(tvd.from);
      const endDate = tvd.to ? new Date(tvd.to) : new Date(new Date().getFullYear(), 11, 31);

      while (currentDate.getFullYear() <= endDate.getFullYear()) {
        const year = currentDate.getFullYear();
        const daysInYear = this.daysForYear(year);

        const yearStart = new Date(year, 0, 1);
        const yearEnd = new Date(year, 11, 31);

        const fromDate = currentDate > yearStart ? currentDate : yearStart;
        const toDate = endDate < yearEnd ? endDate : yearEnd;

        const days = (toDate.getTime() - fromDate.getTime()) / (1000 * 60 * 60 * 24) + 1;

        if (year === lastYear) {
          const entitlement = (tvd.amount / daysInYear) * days;

          const takenLastYear = prevVacationDays
            .filter(
              (vacation) =>
                vacation.from.getFullYear() === lastYear &&
                vacation.to.getFullYear() === lastYear &&
                now > new Date(vacation.to),
            )
            .reduce(
              (acc, vacation) =>
                acc +
                (new Date(vacation.to).getTime() - new Date(vacation.from).getTime()) /
                  (1000 * 60 * 60 * 24) +
                1,
              0,
            );

          carryOver += Math.max(0, entitlement - takenLastYear);
        }

        currentDate = new Date(year + 1, 0, 1);
      }
    });

    carryOver = Math.round(carryOver);

    // Berechnung der verbleibenden Urlaubstage
    const left = leaveEntitlement + carryOver - taken - planed;

    this.leaveEntitlement = leaveEntitlement;
    this.carryOver = carryOver;
    this.taken = taken;
    this.planed = planed;
    this.left = left;
  }

  private daysForYear(year: number) {
    return this.isLeapYear(year) ? 366 : 365;
  }

  private isLeapYear(year: number) {
    return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
  }

  private calculateDaysByType(data, year = new Date().getFullYear()) {
    const daysByType = {};
    const currentYear = year;

    data.forEach((item:any) => {
      const from:any = new Date(item.from);
      const toDate:any = new Date(item.to);

      const to = toDate.getFullYear() === currentYear ? toDate : new Date(`${currentYear}-12-31T23:59:59.999Z`);

      let days = (to - from) / (1000 * 60 * 60 * 24);

      days += 1;

      if (item.halfDay) {
        days = 0.5;
      }

      if (!daysByType[item.type]) {
        daysByType[item.type] = 0;
      }
      daysByType[item.type] += Math.round(days);
    });

    return daysByType;
  }

}
