import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	DestroyRef,
	inject,
	Input,
	OnInit,
	ViewChild,
} from '@angular/core';
import {
	ActionEventArgs,
	EventSettingsModel,
	PopupOpenEventArgs,
	ScheduleComponent,
} from '@syncfusion/ej2-angular-schedule';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Abscence } from 'src/app/entities/abscences/models/abscence.model';
import { ProjectDataCacheService } from 'src/app/project-data-cache.service';
import { DataService } from 'src/app/shared/services/data.service';
import { InteractionsService } from 'src/app/shared/services/interactions.service';
import { Employee } from '../../entities/employees/models/employee.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { KeycloakService } from 'keycloak-angular';
import { HolidaysHttpRequestService } from '../../entities/holidays/services/holidays-http-request.service';
import { SharedService } from '../../shared/services/shared.service';
import moment from 'moment';
import 'moment/locale/de';
import { GermanStateService } from '../../shared/services/german-state.service';
import { ToastModel } from '@syncfusion/ej2-angular-notifications';
import { ToastUtility } from '@syncfusion/ej2-notifications';
import { TimeService } from 'src/app/shared/services/time.service';
import { Subject, takeUntil } from 'rxjs';
import { ContingentService } from 'src/app/shared/services/contingent.service';

@Component({
	selector: 'app-abwesenheitsuebersicht',
	templateUrl: './abwesenheitsuebersicht.component.html',
	styleUrls: ['./abwesenheitsuebersicht.component.scss'],
})
export class AbwesenheitsuebersichtComponent implements AfterViewInit, OnInit {
	@Input()
	public prefix: string = '';
	destroyRef = inject(DestroyRef);
	abscences: any;
	lastYearAbscences: any = 0;
	plannedAbscences: any = 0;
	eventSettings: EventSettingsModel = {};
	currentEmployee: Employee;
	currentEmployeeTargetVacationDays: any;
	selectedAbscence: Abscence | null;
	publicHolidaysCount: any;
	publicHolidays: any;
	selectedYear: any;
	tempOvertime: any;
	public currentYear: number = new Date().getFullYear();
	remaining: number = 0;
	private pendingAbsences: Abscence[] = [];
	private $destroy = new Subject<void>();

	public constructor(
		public readonly projectDataCacheService: ProjectDataCacheService,
		public readonly interactionsService: InteractionsService,
		public readonly dataService: DataService,
		public readonly authService: AuthService,
		public readonly angularKeycloak: KeycloakService,
		public readonly holidaysHttp: HolidaysHttpRequestService,
		private sharedService: SharedService,
		private germanStateService: GermanStateService,
		private cdr: ChangeDetectorRef,
		private readonly timeService: TimeService,
		public readonly contingentService: ContingentService
	) {
		this.eventSettings = {
			dataSource: [],
			allowEditing: true,
			allowAdding: true,
			allowDeleting: true,
			editFollowingEvents: true,
			// Mapper to our fields
			fields: {
				id: 'id',
				subject: { name: 'type' },
				description: { name: 'comment' },
				startTime: { name: 'from' },
				endTime: { name: 'to' },
			},
		};

		this.updateCurrentEmployee();
	}

	@ViewChild('scheduleObj') public scheduleObj!: ScheduleComponent;

	public ngAfterViewInit(): void {
		// if (this.dataService.abscencesCacheService.abscences?.length > 0) this.updateScheduler();

		this.interactionsService.abscencesService.updated$
			.pipe(takeUntilDestroyed(this.destroyRef))
			.subscribe(() => {
				this.updateScheduler();
			});
	}

	async ngOnInit() {
		this.sharedService.changeComponentName('Abwesenheitsübersicht');
		// this.dataService.employeesService.findAll();
		this.dataService.abscencesService.findAll();

		this.addPublicHolidays();

		this.authService.selectedUser$.subscribe((res) => {
			this.contingentService.init();
		});
		this.contingentService.init();

		this.cdr.detectChanges();
	}

	calculateLeaveDaysPerYear(leaves) {
		const result = {};

		leaves.forEach(({ from, to, amount }) => {
			const startDate = new Date(from);
			const endDate = to
				? new Date(to)
				: new Date(startDate.getFullYear(), 11, 31);
			const totalAmount = parseFloat(amount);

			let currentYearStart = new Date(startDate.getFullYear(), 0, 1);
			let currentYearEnd = new Date(startDate.getFullYear(), 11, 31);

			while (currentYearStart <= endDate) {
				const year = currentYearStart.getFullYear();

				const rangeStart =
					startDate > currentYearStart ? startDate : currentYearStart;
				const rangeEnd = endDate < currentYearEnd ? endDate : currentYearEnd;

				const addOne =
					rangeStart.getFullYear() === rangeEnd.getFullYear() &&
					(rangeStart.getMonth() !== rangeEnd.getMonth() ||
						rangeStart.getDate() !== rangeEnd.getDate())
						? 1
						: 0;

				const daysInRange =
					this.getDaysBetweenDates(rangeStart, rangeEnd) + addOne;

				const isLeapYear =
					year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0);
				const daysInYear = isLeapYear ? 366 : 365;

				console.log(
					'year, totalAmount, daysInRange, daysInYear',
					year,
					totalAmount,
					daysInRange,
					daysInYear
				);

				const leaveDaysForYear = (totalAmount * daysInRange) / daysInYear;

				result[year] = (result[year] || 0) + leaveDaysForYear;

				currentYearStart = new Date(year + 1, 0, 1);
				currentYearEnd = new Date(year + 1, 11, 31);
			}
		});

		return result;
	}

	getDaysBetweenDates(startDate, endDate) {
		const oneDay = 24 * 60 * 60 * 1000;
		if (
			startDate.getFullYear() === endDate.getFullYear() &&
			startDate.getMonth() === endDate.getMonth() &&
			startDate.getDate() === endDate.getDate()
		) {
			return 1;
		}

		return Math.floor((endDate - startDate) / oneDay);
	}

	public ngOnChanges(): void {
		this.calculateRemainingVacationDays();
	}

	updateCurrentEmployee() {
		if (!this.currentEmployee) {
			this.currentEmployee = this.authService.selectedEmployee;
			this.currentEmployeeTargetVacationDays =
				this.currentEmployee &&
				this.currentEmployee.targetVacationDayses &&
				this.currentEmployee.targetVacationDayses[0];
			// Handle potential absence data being falsy (e.g., null or undefined)
			const absences = this.currentEmployee?.abscences
				? this.currentEmployee.abscences
				: [];

			if (this.scheduleObj) {
				// Combine absences with Feiertags (if any)
				this.abscences = this.publicHolidays
					? absences.concat(this.publicHolidays)
					: absences;
				this.updateSchedulerWithAbscences(this.abscences);
			}
		}

		// Subscribe to current user observable to get initial employee data
		this.authService.selectedUser$
			.pipe(takeUntil(this.$destroy))
			.subscribe((employee) => {
				this.currentEmployee = JSON.parse(JSON.stringify(employee));

				// Handle potential absence data being falsy (e.g., null or undefined)
				const absences = employee?.abscences
					? JSON.parse(JSON.stringify(employee.abscences))
					: [];
				this.getCommentWithSubstitutionName(absences);

				// Set target vacation day if available
				if (
					employee?.targetVacationDayses &&
					employee?.targetVacationDayses?.length > 0
				) {
					this.currentEmployeeTargetVacationDays =
						employee.targetVacationDayses[0];
				}
				if (this.scheduleObj) {
					// Combine absences with Feiertags (if any)
					this.abscences = this.publicHolidays
						? absences.concat(this.publicHolidays)
						: absences;
					// if (this.currentEmployee) {
					// 	this.addPublicHolidays();
					// }
					this.addPublicHolidays();
					this.updateSchedulerWithAbscences(this.abscences);
				}
			});

			this.dataService.abscencesService.updated$
			.pipe(takeUntil(this.$destroy))
			.subscribe(() => {
			  // Filter absences for current employee (no longer filtering out "requested" absences)
			  const absences =
				this.dataService.abscencesCacheService.abscences.filter(
				  (abscence) => abscence.employee?.id === this.currentEmployee?.id
				);

			  this.getCommentWithSubstitutionName(absences);

			  // DO NOT FILTER OUT REQUESTED ABSENCES HERE!
			  this.updateSchedulerWithAbscences(absences.concat(this.publicHolidays || []));
			  this.abscences = absences.concat(this.publicHolidays || []);
		  });

	}

	addPublicHolidays() {
		this.holidaysHttp
			.findPublicHolidays(this.selectedYear)
			.pipe(takeUntil(this.$destroy))
			.subscribe((holidays) => {
				const stateToApply = this.currentEmployee.stateToApply;
				let stateHolidays = holidays.NATIONAL;

				if (stateToApply) {
					const stateShortForm =
						this.germanStateService.getShortForm(stateToApply);
					if (stateShortForm && holidays[stateShortForm]) {
						stateHolidays = holidays[stateShortForm];
					}
				}

				this.publicHolidays = Object.entries(stateHolidays).map(
					([holidayName, holidayDetails]: [string, any]) => {
						return {
							type: 'Feiertag',
							name: holidayName, // This is the holiday name
							from: new Date(holidayDetails.datum),
							to: new Date(holidayDetails.datum),
							comment: holidayDetails.hinweis,
							halfDay: false,
						};
					}
				);
				this.publicHolidaysCount = this.publicHolidays.length;

				if (Array.isArray(this.scheduleObj?.eventSettings?.dataSource)) {
					let absencesWithNames = this.abscences?.filter((item) =>
						item.hasOwnProperty('name')
					);
					let uniquePublicHolidays = [
						...new Map(
							absencesWithNames
								?.concat(this.publicHolidays)
								?.map((item) => [item['name'], item])
						).values(),
					];
					let absencesWithoutNames = this.abscences?.filter(
						(item) => !item.hasOwnProperty('name')
					);
					let absences = absencesWithoutNames.concat(uniquePublicHolidays);
					this.updateSchedulerWithAbscences(absences);
				}
			});
	}

	filterAbsencesByYear(absences: Abscence[]): Abscence[] {
		return absences.filter((abscence: Abscence) => {
			return new Date(abscence.from).getFullYear() == this.currentYear;
		});
	}

	updateSchedulerWithAbscences(abscences: Abscence[]): void {
		if (this.scheduleObj?.eventSettings && abscences?.length > 0) {
			const events = abscences.map((event) => {
				const isRequestedLeave = event.substitutionStatus === 'requested';

				return {
					...event,
					to: new Date(new Date(event.to).getTime() + 1000),
					// Don't add clock icon to the title, use a different indicator like "(Angefragt)"
					subject: isRequestedLeave ? `(Angefragt) ${event.type}` : event.type,
					description: event.comment,
					cssClass: isRequestedLeave ? 'requested-vacation' : '',
				};
			});

			this.scheduleObj.eventSettings.dataSource = events;
			setTimeout(() => this.colorDots(abscences), 500);
		}
	}

	updateScheduler(): void {
		// Check if this.scheduleObj, this.scheduleObj.eventSettings, this.scheduleObj.eventSettings.dataSource, and this.abscences are not null or undefined
		if (
			this.scheduleObj &&
			this.scheduleObj.eventSettings &&
			this.scheduleObj.eventSettings.dataSource &&
			this.abscences
		) {
			let absences = this.abscences;
			let sch = this.scheduleObj.eventSettings.dataSource;

			// Check if absences is not empty and is the same as the dataSource
			if (absences.length > 0 && this.isSameDataSource(absences, sch)) {
				let dotIsThere = this.checkIfDotIsThere(absences[0]);
				if (dotIsThere) {
					this.colorDots(absences);
				} else {
					// Check if absences[0] is not null or undefined before using it
					if (absences[0]) {
						this.waitForDot(
							absences[0].from.toLocaleDateString('de-DE', {
								weekday: 'long',
								year: 'numeric',
								month: 'long',
								day: 'numeric',
							})
						);
					}
				}
			}
		}
	}

	public updateOvertime(): void {
		this.timeService.selectedDate = new Date();
		this.timeService.currentEmployee = this.currentEmployee;
		this.timeService.loadTimeEntries();
		this.timeService.calculateTargetWorkingHours(this.currentEmployee);
		this.timeService.calculateValues(this.timeService.timeEntries);
		this.tempOvertime = this.timeService.userOvertime;
	}

	isSameDataSource(array1, array2): boolean {
		if (array1.length !== array2.length) {
			return false;
		}

		for (let i = 0; i < array1.length; i++) {
			const obj1 = array1[i];
			const obj2 = array2[i];

			const keys1 = Object.keys(obj1);
			const keys2 = Object.keys(obj2);

			if (keys1.length !== keys2.length) {
				return false;
			}

			for (const key of keys1) {
				if (obj1[key] !== obj2[key]) {
					return false;
				}
			}
		}

		return true;
	}

	waitCounter = 0;

	waitForDot(datumText: string) {
		const maxAttempts = 20;
		let attempts = 0;

		const checkDot = () => {
			attempts++;
			const spanElement = document.querySelector(`span[title="${datumText}"]`);
			if (spanElement || attempts >= maxAttempts) {
				if (spanElement) {
					this.colorDots(this.dataService.abscencesCacheService.abscences);
				}
			} else {
				setTimeout(checkDot, 50);
			}
		};

		checkDot();
	}

	colorEvent(event: any) {
		if (this.abscences.length > 0) {
			let spanElement = event.element.querySelector('span');
			let title = spanElement.getAttribute('title');
			const titleDate = moment(title, 'dddd, DD. MMMM YYYY', 'de').toDate();

			// Find all absences for this date
			const absencesOnThisDay = this.abscences
				.concat(this.publicHolidays || [])
				.filter(absence => {
					const fromDate = moment(absence.from);
					const toDate = moment(absence.to);

					return (
						moment(titleDate).isBetween(fromDate, toDate, 'day', '()') ||
						moment(titleDate).isSame(fromDate, 'day') ||
						moment(titleDate).isSame(toDate, 'day')
					);
				});

			// Find pending requests for this date
			const pendingRequests = absencesOnThisDay.filter(absence =>
				absence.substitutionStatus === 'requested' && absence.type === 'Bezahlter Urlaub'
			);

			let index = this.abscences
				.concat(this.publicHolidays)
				.findIndex((absence) => {
					const fromDate = moment(absence.from);
					const toDate = moment(absence.to);

					return (
						moment(titleDate).isBetween(fromDate, toDate, 'day', '()') ||
						moment(titleDate).isSame(fromDate, 'day') ||
						moment(titleDate).isSame(toDate, 'day')
					);
				});

			setTimeout(() => {
				// Style the appointments in the wrapper
				let appointmentDivs = document.querySelectorAll(
					'.e-more-appointment-wrapper > .e-appointment'
				);
				let absences = this.abscences.concat(this.publicHolidays);
				appointmentDivs.forEach((div) => {
					const absence = absences[index];
					// Only apply requested-vacation class for Bezahlter Urlaub with requested status
					if (absence.substitutionStatus === 'requested' && absence.type === 'Bezahlter Urlaub') {
						div.classList.add('requested-vacation');
						div.setAttribute('data-leave-type', absence.type);
						div.setAttribute('data-leave-status', 'requested');
						div.setAttribute('title', `Angefragt: ${absence.type}`);
					} else {
						// Regular leave styling
						switch (absence.type) {
							case 'Krankheit':
								div.classList.add('type-krankheit');
								break;
							case 'Bezahlter Urlaub':
								div.classList.add('type-urlaub');
								break;
							case 'Feiertag':
								(div as HTMLElement).style.padding = '2px 0 2px 7px';
								div.innerHTML = absence.name;
								div.classList.add('type-feiertag');
								break;
							case 'Elternzeit':
								div.classList.add('type-elternzeit');
								break;
						}
					}
				});

				// Show pending request indicator in the popup only for Bezahlter Urlaub
				if (pendingRequests.length > 0) {
					const popupElement = document.querySelector('.e-quick-popup-wrapper');
					if (popupElement && !popupElement.querySelector('.pending-request-indicator')) {
						const indicatorDiv = document.createElement('div');
						indicatorDiv.className = 'pending-request-indicator';

						let indicatorContent = `
							<div style="color: #ff5602; margin-top: 10px; font-weight: bold; padding: 8px; border: 1px dashed #ff5602; border-radius: 4px;">
								<div style="margin-bottom: 5px;"><span class="clock-icon">🕑</span> Ausstehende Anfrage(n):</div>
						`;

						pendingRequests.forEach((leave, idx) => {
							if (idx > 0) indicatorContent += `<hr style="border-top: 1px dashed #ff5602; margin: 5px 0;">`;

							const fromDate = new Date(leave.from).toLocaleDateString('de-DE');
							const toDate = new Date(leave.to).toLocaleDateString('de-DE');
							const dateRange = fromDate === toDate ? fromDate : `${fromDate} - ${toDate}`;

							const employeeName = leave.employee ?
								`${leave.employee.firstname} ${leave.employee.lastname}` : 'Mitarbeiter';

							indicatorContent += `
								<div><strong>Typ:</strong> ${leave.type}</div>
								<div><strong>Zeitraum:</strong> ${dateRange}</div>
								<div><strong>Beantragt von:</strong> ${employeeName}</div>
							`;

							if (leave.substitution) {
								const matchedUser = this.dataService.employeesCacheService.employees.find(
									(user: Employee) => user.id === leave.substitution
								);
								if (matchedUser) {
									indicatorContent += `<div><strong>Vertretung:</strong> ${matchedUser.firstname} ${matchedUser.lastname}</div>`;
								}
							}
						});

						indicatorContent += `</div>`;
						indicatorDiv.innerHTML = indicatorContent;
						popupElement.appendChild(indicatorDiv);
					}
				}
			}, 100);
		}
	}

	colorDots(absences: Abscence[]) {
		let options: Intl.DateTimeFormatOptions = {
			weekday: 'long',
			year: 'numeric',
			month: 'long',
			day: 'numeric',
		};

		absences.forEach((absence: Abscence) => {
			const dates = getDatesBetween(absence.from, absence.to);
			dates.forEach((date: Date) => {
				const datumString = date.toLocaleDateString('de-DE', options);
				document.querySelectorAll('.e-appointment').forEach((appointmentDiv) => {
					const siblingSpan = appointmentDiv.previousElementSibling;
					if (siblingSpan?.getAttribute('title') === datumString) {
						// Only apply requested-vacation class for Bezahlter Urlaub with requested status
						if (absence.substitutionStatus === 'requested' && absence.type === 'Bezahlter Urlaub') {
							appointmentDiv.classList.add('requested-vacation');
							appointmentDiv.setAttribute('data-leave-type', absence.type);
							appointmentDiv.setAttribute('data-leave-status', 'requested');
							appointmentDiv.setAttribute('title', `Angefragt: ${absence.type}`);
						} else {
							// Regular leave styling
							switch (absence.type) {
								case 'Krankheit':
									appointmentDiv.classList.add('type-krankheit');
									appointmentDiv.setAttribute('data-leave-type', 'Krankheit');
									appointmentDiv.setAttribute('title', 'Krankheit');
									break;
								case 'Bezahlter Urlaub':
									appointmentDiv.classList.add('type-urlaub');
									appointmentDiv.setAttribute('data-leave-type', 'Bezahlter Urlaub');
									appointmentDiv.setAttribute('title', 'Bezahlter Urlaub');
									break;
								case 'Feiertag':
									appointmentDiv.classList.add('type-feiertag');
									appointmentDiv.setAttribute('data-leave-type', 'Feiertag');
									appointmentDiv.setAttribute('title', (absence as any).name || 'Feiertag');
									break;
								case 'Elternzeit':
									appointmentDiv.classList.add('type-elternzeit');
									appointmentDiv.setAttribute('data-leave-type', 'Elternzeit');
									appointmentDiv.setAttribute('title', 'Elternzeit');
									break;
							}
						}
					}
				});
			});
		});
	}

	onActionComplete(args: ActionEventArgs): void {
		if (args.requestType === 'eventCreate') {
			this.colorDots(this.dataService.abscencesCacheService.abscences);
		}
		if (args.requestType === 'eventChanged') {
			this.showAbwesenheitDialog = true;
			this.selectedAbscence = args.data as Abscence;
			this.colorDots(this.dataService.abscencesCacheService.abscences);
		}
		if (args.requestType === 'eventRemoved') {
			if (args?.data) {
				let abscence = args.data[0];

				if (this.authService.currentEmployee.role === 'admin' || this.authService.currentEmployee.role === 'branch-admin') {
					this.interactionsService.abscencesService.delete(abscence as Abscence);

					this.dataService.reloadAbsences();
					this.dataService.reloadTargetVacations();
					this.contingentService.init();
					this.updateScheduler();
					this.updateOvertime();
					this.updateCurrentEmployee();
					this.contingentService.calculate(this.selectedYear);
				}
				else if (this.authService.currentEmployee.role === 'employee') {
					if (abscence.employee && abscence.employee.id === this.authService.currentEmployee.id) {
						const threesDaysAgo = moment().subtract(3, 'days').startOf('day');

						const absenceDate = moment(abscence.createdAt).startOf('day');

						if (absenceDate.isBefore(threesDaysAgo)) {
							const toast = ToastUtility.show(
								'Mitarbeiter können nur Abwesenheiten löschen, die in den letzten 3 Tagen erstellt wurden.',
								'Error',
								5000
							);
							return;
						}

						this.interactionsService.abscencesService.delete(abscence as Abscence);

						this.dataService.reloadAbsences();
						this.dataService.reloadTargetVacations();
						this.contingentService.init();
						this.updateScheduler();
						this.updateOvertime();
						this.updateCurrentEmployee();
						this.contingentService.calculate(this.selectedYear);
					} else {
						const toast = ToastUtility.show(
							'Mitarbeiter können nur ihre eigenen Abwesenheiten löschen.',
							'Error',
							5000
						);
						return;
					}
				}
			}
		}
		if (args.requestType === 'dateNavigate') {
			this.selectedYear= this.scheduleObj.selectedDate.getFullYear();
			this.contingentService.calculate(this.selectedYear);
			this.addPublicHolidays()
		}
	}

	onPopupOpen(args: PopupOpenEventArgs): void {
		if(args.type !== "EventContainer"){
			args.cancel = this.authService.currentEmployee.role !== 'admin';
		}

		if (args.type === 'Editor') {
			args.cancel = true;

			const isAdmin = this.authService.currentEmployee.role === 'admin' || this.authService.currentEmployee.role === 'branch-admin';
			const isOwner = args.data && args.data['employee']?.id === this.authService.currentEmployee.id;
			const isWithinThreeDays = args.data ? this.isWithinThreeDays(new Date(args.data['from'])) : false;

			if (isAdmin || (isOwner && isWithinThreeDays)) {
				Promise.resolve().then(() => {
					this.showAbwesenheitDialog = true;
					if(args.data){
						const comment = args.data['comment'].toString().split('Kommentar: ')[1]
						this.selectedAbscence = {...args.data, comment}as Abscence;
					}
					this.cdr.detectChanges();
				});
			} else {
				ToastUtility.show(
					'Sie können nur Ihre eigenen Abwesenheiten bearbeiten, die in den letzten 3 Tagen liegen.',
					'Error',
					5000
				);
			}
		}
		if (args.type === 'QuickInfo') {
			let editButton: HTMLElement = args.element.querySelector(
				'.e-event-edit'
			) as HTMLElement;
			let deleteButton: HTMLElement = args.element.querySelector(
				'.e-event-delete'
			) as HTMLElement;

			const isAdmin = this.authService.currentEmployee.role === 'admin' || this.authService.currentEmployee.role === 'branch-admin';
			const isOwner = args.data && args.data['employee']?.id === this.authService.currentEmployee.id;
			const isWithinThreeDays = args.data ? this.isWithinThreeDays(new Date(args.data['from'])) : false;

			if (editButton) {
				editButton.innerText = 'Bearbeiten';
				editButton.style.display = (isAdmin || (isOwner && isWithinThreeDays)) ? 'block' : 'none';
			}
			if (deleteButton) {
				deleteButton.innerText = 'Löschen';
				deleteButton.style.display = (isAdmin || (isOwner && isWithinThreeDays)) ? 'block' : 'none';
			}
		}
		if (args.type === 'DeleteAlert') {
			// Change the text of the edit and delete buttons
			let noButton: HTMLElement = args.element.querySelector(
				'.e-quick-dialog-cancel'
			) as HTMLElement;
			let yesButton: HTMLElement = args.element.querySelector(
				'.e-quick-dialog-delete'
			) as HTMLElement;
			let dialog: HTMLElement = args.element.querySelector(
				'.e-dlg-content'
			) as HTMLElement;
			let header: HTMLElement = args.element.querySelector(
				'.e-dlg-header'
			) as HTMLElement;
			if (yesButton) {
				yesButton.innerText = 'Löschen';
			}
			if (noButton) {
				noButton.innerText = 'Abbrechen';
			}
			if (dialog) {
				dialog.innerText =
					'Sind Sie sicher, dass Sie diese Abwesenheit löschen möchten?';
			}
			if (header) {
				header.innerText = 'Die Abwesenheit löschen';
			}
		}
	}

	private isWithinThreeDays(date: Date): boolean {
		const today = new Date();
		today.setHours(0, 0, 0, 0);

		const threeDaysAgo = new Date(today);
		threeDaysAgo.setDate(today.getDate() - 3);

		const checkDate = new Date(date);
		checkDate.setHours(0, 0, 0, 0);

		return checkDate >= threeDaysAgo ;
	}

	getAppointmentElement(spanElement: Element): Element {
		let appointmentDiv =
			spanElement.parentElement?.querySelector('.e-appointment');
		return appointmentDiv || spanElement;
	}

	checkIfDotIsThere(abscence: Abscence) {
		let options: Intl.DateTimeFormatOptions = {
			weekday: 'long',
			year: 'numeric',
			month: 'long',
			day: 'numeric',
		};
		let datumString = abscence.from.toLocaleDateString('de-DE', options);

		// Span element next to the dot element
		let spanElement = document.querySelector(
			'span[title="' + datumString + '"]'
		);

		let appointmentDiv: Element;
		if (!spanElement) return false;
		else appointmentDiv = this.getAppointmentElement(spanElement);

		return !!appointmentDiv;
	}

	isCurrentEmployeeSelected(): boolean {
		return (
			this.currentEmployee &&
			this.authService.currentEmployee?.id === this.currentEmployee?.id
		);
	}

	showAbwesenheitDialog = false;
	showConfirmationDialog = false;

	public onButtonClicked(): void {
		if (
			!this.isCurrentEmployeeSelected() &&
			this.authService.currentEmployee.role !== 'admin'
		) {
			let toast: ToastModel = ToastUtility.show(
				'Sie können keine Abwesenheiten für andere Benutzer hinzufügen.',
				'Error',
				5000
			);
			return;
		}

		Promise.resolve().then(() => {
			this.showAbwesenheitDialog = true;
			this.cdr.detectChanges();
		});
	}

	public fields = { text: 'text', value: 'value' };

	public firstMonthOfYear(args: Record<string, number>): void {
		this.scheduleObj.firstMonthOfYear = args['value'];
	}

	public numberOfMonths(args: Record<string, number>): void {
		this.scheduleObj.monthsCount = args['value'];
	}

	newLeaveRequest: Partial<Abscence> = {};

	public handleNext(data: any) {
		const { leaveRequests, currentEmployee } = data;
		this.updateOvertime();

		if (currentEmployee.role === 'employee') {
			const today = new Date();
			today.setHours(0, 0, 0, 0);

			const hasPastDate = leaveRequests.some((request) => {
				const requestDate = new Date(request.from);
				requestDate.setHours(0, 0, 0, 0);
				return requestDate < today;
			});

			if (hasPastDate) {
				ToastUtility.show(
					'Abwesenheit kann nicht in der Vergangenheit liegen',
					'Error',
					5000
				);
				return;
			}
		}

		leaveRequests.forEach((newLeaveRequest: Partial<Abscence>) => {
			if (newLeaveRequest.from) {
				newLeaveRequest.from = new Date(
					Date.UTC(
						newLeaveRequest.from.getFullYear(),
						newLeaveRequest.from.getMonth(),
						newLeaveRequest.from.getDate(),
						0,
						0,
						0,
						0
					)
				);
			}

			if (newLeaveRequest.to) {
				newLeaveRequest.to = new Date(
					Date.UTC(
						newLeaveRequest.to.getFullYear(),
						newLeaveRequest.to.getMonth(),
						newLeaveRequest.to.getDate(),
						0,
						0,
						0,
						0
					)
				);
			}

			newLeaveRequest.employee = currentEmployee;

			if (newLeaveRequest.type === 'Bezahlter Urlaub') {
				if (!newLeaveRequest.from || !newLeaveRequest.to) {
					throw new Error('From and To dates must be provided.');
				}
				const days = this.calculateDays(newLeaveRequest.from.toString(), newLeaveRequest.to.toString(), newLeaveRequest.halfDay);
				const oldDays = this.selectedAbscence
					? this.calculateDays(this.selectedAbscence.from.toString(), this.selectedAbscence.to.toString(), this.selectedAbscence.halfDay)
					: 0;
				const remainingDays = this.contingentService.left + oldDays - days;
				if (remainingDays < 0) {
					ToastUtility.show(
						'Es scheint, dass Sie nicht genügend bezahlte Urlaubstage haben, um diesen Urlaub zu beantragen.',
						'Error',
						5000
					);
					return;
				}
			}

			this.interactionsService.abscencesService.create({
				...newLeaveRequest,
				substitutionStatus: 'requested',
			});

			setTimeout(() => {
				this.updateOvertime();
				this.selectedAbscence = null;
			}, 500);
		});
		this.dataService.reloadAbsences();
		this.contingentService.init();
		this.showAbwesenheitDialog = false;
	}


	public handleSend() {
		this.showConfirmationDialog = false;

		if (Array.isArray(this.newLeaveRequest)) {
			this.newLeaveRequest.forEach((dailyLeaveRequest) => {
				const startDate = dailyLeaveRequest.from || new Date();
				const endDate = dailyLeaveRequest.to || new Date();

				if (this.selectedAbscence) {
					this.interactionsService.abscencesService.delete(this.selectedAbscence);
				}

				this.interactionsService.abscencesService.create({
					...dailyLeaveRequest,
					substitutionStatus: 'requested',
				});
			});
		} else {
			const startDate = this.newLeaveRequest.from || new Date();
			const endDate = this.newLeaveRequest.to || new Date();

			if (this.selectedAbscence) {
				this.interactionsService.abscencesService.delete(this.selectedAbscence);
			}

			this.interactionsService.abscencesService.create({
				...this.newLeaveRequest,
				substitutionStatus: 'requested',
			});
		}

		setTimeout(() => {
			this.updateOvertime();
			this.selectedAbscence = null;
		}, 2000);
		this.dataService.reloadAbsences();
		this.contingentService.init();
	}


	calculateTotalTakenAbsenceDays(): number {
		if (this.abscences) {
			let totalDays = 0;
			let currentDate = new Date();
			let filteredAbsences = this.abscences.filter((ab: Abscence) => {
				return ab.type == 'Bezahlter Urlaub' && ab.from <= currentDate;
			});

			for (let absence of filteredAbsences) {
				// Create date objects from the absence 'from' and 'to' dates
				let fromDate = new Date(absence.from);
				let toDate = new Date(absence.to);

				const holidaysCount = this.getHolidaysBetween(fromDate, toDate);

				// Calculate the difference in time between the two dates
				let diffInTime = toDate.getTime() - fromDate.getTime();

				// Calculate the difference in days and add 1 to include the start day
				let diffInDays = diffInTime / (1000 * 3600 * 24) + 1;

				diffInDays -= holidaysCount;

				// Add the difference in days to the total
				totalDays += diffInDays;
			}
			return totalDays;
		} else {
			return 0;
		}
	}

	getHolidaysBetween(fromDate: Date, toDate: Date): number {
		let holidaysCount = 0;
		let currentDate = new Date(fromDate);
		currentDate.setHours(1);
		while (currentDate <= toDate) {
			const isHoliday = this.publicHolidays?.find((holiday: any) => {
				return holiday.from.toDateString() === currentDate.toDateString();
			});
			if (isHoliday) {
				holidaysCount++;
			}
			currentDate.setDate(currentDate.getDate() + 1);
		}
		return holidaysCount;
	}

	calculateTotalPlannedAbsenceDays(): number {
		if (this.abscences) {
			let totalDays = 0;
			let currentDate = new Date();
			let filteredAbsences = this.abscences.filter((ab: Abscence) => {
				return ab.type == 'Bezahlter Urlaub' && ab.from > currentDate;
			});

			for (let absence of filteredAbsences) {
				// Create date objects from the absence 'from' and 'to' dates
				let fromDate = new Date(absence.from);
				let toDate = new Date(absence.to);

				// Calculate the difference in time between the two dates
				let diffInTime = toDate.getTime() - fromDate.getTime();

				// Calculate the difference in days and add 1 to include the start day
				let diffInDays = diffInTime / (1000 * 3600 * 24) + 1;

				// Add the difference in days to the total
				totalDays += diffInDays;
			}
			return totalDays;
		} else {
			return 0;
		}
	}

	calculateRemainingVacationDays() {
		let totalTakenAbsenceDays = this.calculateTotalTakenAbsenceDays();
		this.plannedAbscences = this.calculateTotalPlannedAbsenceDays();
		this.remaining =
			this.currentEmployeeTargetVacationDays?.amount -
			totalTakenAbsenceDays +
			this.lastYearAbscences -
			this.plannedAbscences;
	}

	convertDecimalToDaysAndHours(decimal: number): string {
		const days = Math.floor(decimal);
		const hours = Math.round((decimal - days) * 8);

		return `${days} Tage und ${hours} Stunden`;
	}

	calculateAbscenceCounts(): any {
		if (this.abscences) {
			let filteredAbsences = this.filterAbsencesByYear(this.abscences);
			const totalElternzeit = filteredAbsences.filter(
				(a: any) => a.type === 'Elternzeit'
			).length;
			const totalKrankheit = filteredAbsences.filter(
				(a: any) => a.type === 'Krankheit'
			).length;
			const totalBezahlterUrlaub = filteredAbsences.filter(
				(a: any) => a.type === 'Bezahlter Urlaub'
			).length;

			return { totalElternzeit, totalKrankheit, totalBezahlterUrlaub };
		} else {
			return { totalElternzeit: 0, totalKrankheit: 0, totalBezahlterUrlaub: 0 };
		}
	}

	calculateLastYearAbscences(): number {
		if (this.abscences) {
			let filteredAbsences = this.abscences.filter((ab: Abscence) => {
				return ab.type !== 'Feiertag';
			});

			if (filteredAbsences) {
				const lastYear = this.currentYear - 1;
				const lastYearAbsences = filteredAbsences.filter(
					(a: any) => new Date(a.from).getFullYear() === lastYear
				);
				this.lastYearAbscences = lastYearAbsences.length;
			}
		}
		return this.lastYearAbscences;
	}

	onNavigated($event: any) {
		this.currentYear = $event.currentDate.getFullYear();
		this.selectedYear = this.currentYear;
		this.addPublicHolidays();
		this.updateSchedulerWithAbscences(this.abscences);
	}

	getCommentWithSubstitutionName(absences) {
		if (absences.length > 0) {
			absences.forEach((absence) => {
				const matchedUser =
					this.dataService.employeesCacheService.employees.find(
						(user: Employee) => user.id === absence.substitution
					);
				if (matchedUser && !absence.comment?.includes('Vertretung')) {
					absence.comment = `Vertretung: ${matchedUser.firstname} ${matchedUser.lastname}
					Kommentar: ${absence.comment}`;
				}
			});
		}
	}

	public calculateDays(
		fromDate: string,
		toDate: string,
		halfDay: boolean = false
	): number {
		const from = new Date(fromDate);
		const to = new Date(toDate);
		const currentYear = from.getFullYear();

		const adjustedTo =
			to.getFullYear() === currentYear
				? to
				: new Date(`${currentYear}-12-31T23:59:59.999Z`);

		if (from > adjustedTo) {
			return 0;
		}

		let days =
			(adjustedTo.getTime() - from.getTime()) / (1000 * 60 * 60 * 24) + 1;

		if (halfDay) {
			days = 0.5;
		}

		return days;
	}

	closePopup() {
		Promise.resolve().then(() => {
			this.showAbwesenheitDialog = false;
			this.showConfirmationDialog = false;
			this.selectedAbscence = null;
			this.cdr.detectChanges();
		});
	}

	ngOnDestroy(): void {
		this.$destroy.next();
		this.$destroy.complete();
	}

	protected readonly Object = Object;
}

function getDatesBetween(startDate: Date, endDate: Date): Date[] {
	let dates: Date[] = [];

	let currentDate = new Date(startDate);
	let adjustedEndDate = new Date(endDate);

	while (currentDate <= adjustedEndDate) {
		dates.push(new Date(currentDate));
		currentDate.setDate(currentDate.getDate() + 1);
	}

	return dates;
}
