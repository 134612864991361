import { inject } from '@angular/core';
import { CanActivateFn, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';

export const roleGuard: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot
) => {
  const router = inject(Router);
  const authService = inject(AuthService);

  const permissionMap: Record<string, string[]> = {
    yearly: ['admin'],
    employees: ['admin', 'branch-admin']
  };

  const permissionKey = route.data['permissionKey'];

  if (!permissionKey || !permissionMap[permissionKey]) {
    return true;
  }

  const requiredRoles = permissionMap[permissionKey];

  const hasRequiredRole = requiredRoles.some(role => {
    switch (role) {
      case 'admin': return authService.isAdmin();
      case 'branch-admin': return authService.isBranchAdmin();
      case 'user': return (!authService.isBranchAdmin() && !authService.isAdmin()); 
      default: return false;
    }
  });

  if (!hasRequiredRole) {
    router.navigate(['']);
    return false;
  }

  return true;
};
